import React, { useContext, useRef } from "react";
import { ControlFlavor, Controls } from "../../components/controls/Controls";
import { DeviationGraph } from "../../components/deviation-graph/DeviationGraph";
import { AutoCenteringModes, getEdgeColor, getEdgeHighlightColor, getLegendProps, IDfGraph, ProcessGraph, ZoomControlLocations } from "../../components/dfg/DfGraph";
import { getNodeMarkupTimings } from "../../components/dfg/nodes/NodeMarkupFactory";
import { Dimensions, getDimensionParameters } from "../../components/dimension/Dimension";
import SideStatisticsCaseGantt from "../../components/side-statistics/SideStatisticsCaseGantt";
import SideStatisticsTime from "../../components/side-statistics/SideStatisticsTime";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { SessionContext, hasNetTransitionTimes } from "../../contexts/SessionContext";
import { GraphOrientation, SettingsContext, SettingsType, SortByType, VisualizationType } from "../../contexts/SettingsContext";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { AnalysisType } from "../../hooks/UseGraph";
import { NodeRoles } from "../../models/Dfg";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { getAssignedQuantities, QuantityType } from "../../utils/Quantities";
import { isObjectCentricAvailable } from "../../utils/SettingsUtils";
import CaseGantt from "../gantt/CaseGantt";
import ProcessGantt from "../gantt/ProcessGantt";
import { allowedGroupingReducedWithoutObjectType, allowedGroupingWithoutMachineObject, allowedGroupingWithoutObjectType } from "../../utils/GroupingUtils";
import { ProcessVariants } from "../process-path/ProcessVariants";
import { SideStatisticsProjectDeviation } from "../planning-deviation/SideStatisticsProjectDeviation";
import SideStatisticsGraphDeviation from "../planning-deviation/SideStatisticsGraphDeviation";
import ControlsDeviationGraph, { getDeviationKpis, getValidDeviationGraphControlSettings } from "../planning-deviation/ControlsDeviationGraph";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import DfgControls, { getValidDfgControlSettings } from "../../components/controls/DfgControls";
import { getAllowedKpis, graphKpiControlsGetAllowedStatistics } from "../../models/Kpi";
import { getEdgeLabelText, getEdgeStat, getEnabledComparisonsValueStream } from "../../utils/DfgUtils";
import i18n from "../../i18n";
import { DownloadDfgTrayElement } from "../../components/tray/DownloadDfgTrayElement";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { CaseGanttSetting } from "../../models/ApiTypes";
import { ObjectMerger } from "../../utils/ObjectMerger";
import BenchmarkingKpiControls, { getValidBenchmarkingKpiControlSettings } from "../../components/controls/BenchmarkingKpiControls";
import { EdgeVarianceChart } from "../../components/kpi-chart/EdgeVarianceChart";
import { intersection } from "lodash";
import EdgeKpiChart from "../../components/kpi-chart/EdgeKpiChart";
import { NodeKpiChart } from "../../components/kpi-chart/NodeKpiChart";
import { NodeVarianceChart } from "../../components/kpi-chart/NodeVarianceChart";
import { LogStats } from "../process-kpi-chart/stats/LogStats";
import { ViewSubtitle } from "../../components/tabbed-view/ViewSubtitle";

export function TimingsProcessView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const hasObjects = isObjectCentricAvailable(session.project?.eventKeys);

    const isCaseYieldLabelled = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).length > 0;

    const isTerminalNodeSelected = settings.selection.node?.role === NodeRoles.Start || settings.selection.node?.role === NodeRoles.End;

    const controllerSuffix = (hasObjects ? "-OC" : "");

    const hasPlanningData = session.project?.eventKeysPlan !== undefined;
    const hasRoutings = session.project?.uploads?.routings !== undefined;

    const quantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.baseQuantity);

    const dfgRef = useRef<IDfGraph>(null);

    const showNetEdgeTime = hasNetTransitionTimes(session) && [KpiTypes.NetTransitionTime, KpiTypes.NetThroughputTime].includes(settings.kpi.selectedKpi);

    return <TabbedView
        subtitle={<ViewSubtitle />}
        breadcrumbs={[{
            label: "common.valueStream"
        }]}
        pages={[
            {
                tabTitle: "explorer.dfg",
                tabSlug: "dfg",
                controller: <DfgControls kpis={KpiPresets.valueStreamTimeKpis} kpiOptionLabels={{
                    [KpiTypes.ThroughputTime]: `${i18n.t("common.throughputTime").toString()} / ${i18n.t("common.transitionTime").toString()}`,
                    [KpiTypes.NetThroughputTime]: hasNetTransitionTimes(session)? `${i18n.t("common.netThroughputTime").toString()} / ${i18n.t("common.netTransitionTime").toString()}` : undefined,
                }} />,
                selectionTrigger: (settings) => {
                    return settings.selection.node !== undefined || settings.selection.edge !== undefined;
                },
                stats: <SideStatisticsTime />,
                spotlightId: "Timings-DFG",
                activator: (preferences) => {
                    let temp = viewSettingsInitialization(session, settings, preferences, {
                        aggregationTypes: [AggregationTypes.Product],
                        kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamTimeKpis),
                        quantities: quantities,
                    });
                    if (!temp)
                        return;

                    temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamTimeKpis }));

                    // The allowed comparisons are depending on settings that might have been corrected above,
                    // so we need to run this as a two-step process
                    settings.set(viewSettingsInitialization(session, temp, undefined, {
                        statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                        comparisons: getEnabledComparisonsValueStream(session, temp),
                    }));
                },
                dimensions: getDimensionParameters(session.projectId, "process/dfg", undefined, []),
                content: <><ProcessGraph
                    ref={dfgRef}
                    isObjectCentric={hasObjects}
                    legend={getLegendProps(settings.kpi.selectedKpi)}
                    zoomControlLocation={ZoomControlLocations.FarRight}
                    analysis={AnalysisType.Times}
                    markupFunc={getNodeMarkupTimings}
                    edgeLabelFunc={(edge, settings, session) => {
                        if (![KpiTypes.ThroughputTime, KpiTypes.NetThroughputTime, KpiTypes.Frequency].includes(settings.kpi.selectedKpi))
                            return undefined;
                        return getEdgeLabelText(edge, settings, session);
                    }}
                    nodeHighlightStatFunc={(node) => {
                        return getMainNodeStat(node, settings, session);
                    }}
                    edgeColorFunc={(edge) => getEdgeColor(edge, settings, session)}
                    edgeHighlightStatFunc={(edge, settings, session) => {
                        if (![KpiTypes.ThroughputTime, KpiTypes.NetThroughputTime, KpiTypes.Frequency].includes(settings.kpi.selectedKpi))
                            return undefined;
                        return getEdgeStat(edge, settings, session, {
                            kpiType: showNetEdgeTime? KpiTypes.NetTransitionTime : undefined,
                        });
                    }}
                    edgeHighlightColorFunc={(edge, stat, minStatistic, maxStatistic, scale) => {
                        if (scale === undefined)
                            return undefined;

                        return getEdgeHighlightColor(edge, scale, settings, session);
                    }}
                    centerMode={AutoCenteringModes.None}
                />
                <BackButtonTrayElement />
                <DownloadDfgTrayElement graph={dfgRef.current} filename={i18n.t("common.processGraph").toString() + "-" + i18n.t("time").toString()} />
                </>,
            }, // Process Deviation
            {
                isVisible: hasPlanningData || hasRoutings,
                tabTitle: "common.scheduleDeviation",
                tabSlug: "deviation",
                spotlightId: "Timings-PD-Process",
                selectionTrigger: (settings) => {
                    return settings.selection.node !== undefined || settings.selection.edge !== undefined;
                },
                stats: <>
                    {((!settings.selection.node && !settings.selection.edge) || isTerminalNodeSelected) && <SideStatisticsProjectDeviation />}
                    {(!!settings.selection.node || !!settings.selection.edge) && !isTerminalNodeSelected && <SideStatisticsGraphDeviation />}
                </>,
                controller: <ControlsDeviationGraph />,
                activator: (preferences) => {
                    let temp = viewSettingsInitialization(session, settings, preferences, {
                        aggregationTypes: [AggregationTypes.Product],
                        kpis: intersection(
                            getAllowedKpis(session, settings, KpiPresets.valueStreamTimeDeviationKpis),
                            getDeviationKpis(session),
                        ),
                        groupingKeys: allowedGroupingReducedWithoutObjectType,
                        quantities: quantities,
                        orientation: settings.graph.visualization === VisualizationType.SideBySide ? [GraphOrientation.vertical] : undefined,
                    });

                    if (!temp)
                        return;

                    temp = ObjectMerger.mergeObject(temp, getValidDeviationGraphControlSettings(session, temp));

                    settings.set(viewSettingsInitialization(session, temp, undefined, {
                        statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                    }));
                },
                dimensions: {
                    timing: `/projects/${session.projectId}/timings/process/deviation`,
                },
                content: <>
                    <DeviationGraph />
                </>,
            }, {
                tabTitle: "common.processKpis",
                tabSlug: "benchmarking",
                selectionTrigger: (settings) => {
                    return !!settings.selection.node || !!settings.selection.edge;
                },
                controller: <BenchmarkingKpiControls kpis={KpiPresets.valueStreamTimeBenchmarkingKpis} />,
                stats: <SideStatisticsTime />,
                activator: (preferences) => {
                    let temp = viewSettingsInitialization(session, settings, preferences, {
                        aggregationTypes: [AggregationTypes.Product],
                        kpis: KpiPresets.valueStreamTimeBenchmarkingKpis,
                        groupingKeys: allowedGroupingWithoutMachineObject,
                        quantities: quantities,
                        sortBy: [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.DeviationFromComparison, SortByType.OrderSequences],
                    });

                    if (!temp)
                        return;

                    temp = ObjectMerger.mergeObject(temp, getValidBenchmarkingKpiControlSettings(session, temp, { kpis: KpiPresets.valueStreamTimeBenchmarkingKpis }));

                    const allowedComparisons = getEnabledComparisonsValueStream(session, temp);
                    const newValidation = {
                        comparisons: allowedComparisons,
                        statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                        sortBy: allowedComparisons.includes(KpiComparisons.Planning) ? [SortByType.Kpi, SortByType.Frequency, SortByType.Alphabetical, SortByType.OrderSequences] : undefined
                    };

                    // The allowed comparisons are depending on settings that might have been corrected above,
                    // so we need to run this as a two-step process
                    settings.set(viewSettingsInitialization(session, temp, undefined, newValidation));
                },
                spotlightId: "Timings-TimeKPIs-Order",
                content: <>
                    {[KpiTypes.QueuingTime, KpiTypes.NetTransitionTime].includes(settings.kpi.selectedKpi) && <>
                        {settings.kpi.statistic === StatisticTypes.Variance &&
                            <EdgeVarianceChart analysisType={AnalysisType.Times} />}
                        {settings.kpi.statistic !== StatisticTypes.Variance &&
                            <EdgeKpiChart analysisType={AnalysisType.Times} />}
                    </>}

                    {![KpiTypes.QueuingTime, KpiTypes.NetTransitionTime].includes(settings.kpi.selectedKpi) && <>
                        {settings.kpi.statistic === StatisticTypes.Variance &&
                            <NodeVarianceChart analysisType={AnalysisType.Times} noDataPlaceholder="" pageSlug="timings" />}
                        {settings.kpi.statistic !== StatisticTypes.Variance &&
                            <NodeKpiChart analysisType={AnalysisType.Times} noDataPlaceholder="" pageSlug="timings" />}
                    </>}
                </>,
                dimensions: getDimensionParameters(session.projectId, "process/benchmarking", undefined, []),
            }, {
                tabTitle: "workflows.processPath.processVariants",
                tabSlug: "process-path",
                selectionTrigger: (settings) => {
                    return !!(settings.selection.node || settings.selection.edge);
                },
                activator: (preferences) => {
                    let temp = viewSettingsInitialization(session, settings, preferences, {
                        aggregationTypes: [AggregationTypes.Product],
                        kpis: getAllowedKpis(session, settings, KpiPresets.valueStreamTimeKpis),
                        quantities: getAssignedQuantities(session.project?.eventKeys, QuantityType.Yield, false).map(q => q.baseQuantity),
                    });

                    if (!temp)
                        return;

                    temp = ObjectMerger.mergeObject(temp, getValidDfgControlSettings(session, temp, { kpis: KpiPresets.valueStreamTimeKpis }));

                    settings.set(viewSettingsInitialization(session, temp, undefined, {
                        statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                    }));
                },
                stats: <SideStatisticsTime />,
                controller: <DfgControls kpis={KpiPresets.valueStreamTimeKpis} />,
                spotlightId: "Timings-Variance-ProcessPath",
                dimensions: {
                    timing: `/projects/${session.projectId}/timings/process/process-path`,
                    output: `/projects/${session.projectId}/output/process/process-path`,
                },
                content: <>
                    <ProcessVariants
                        analysisType={AnalysisType.Times}
                    />
                </>,
            }, {
                tabTitle: "explorer.gantt",
                tabSlug: "process-gantt",
                spotlightId: "Timings-ProcessGantt",
                controller: <Controls
                    flavor={ControlFlavor.GanttControls}
                />,
                selectionTrigger: (settings) => {
                    return settings.selection.node !== undefined;
                },
                activator: (preferences) => {
                    const temp = viewSettingsInitialization(session, settings, preferences, {
                        aggregationTypes: [AggregationTypes.Product],
                        groupingKeys: allowedGroupingWithoutObjectType
                    });

                    if (!temp)
                        return;

                    settings.set(viewSettingsInitialization(session, temp, undefined, {
                        statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                    }));
                },
                stats: settings.selection.node !== undefined ? <SideStatisticsTime disableProcessFocus={true} /> : <LogStats />,
                dimensions: {
                    timing: `/projects/${session.projectId}/timings/process/process-gantt`,
                },
                content: <>
                    <ProcessGantt />
                    <BackButtonTrayElement />
                </>,
            }, {
                tabTitle: "explorer.caseGantt",
                tabSlug: "case-gantt",
                spotlightId: "Timings-CaseGantt",
                selectionTrigger: (settings) => {
                    return settings.selection.case !== undefined;
                },
                activator: (preferences) => {
                    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false).map(q => q.baseQuantity);

                    const temp = viewSettingsInitialization(session, settings, preferences, {
                        aggregationTypes: [AggregationTypes.Product],
                        quantities: caseYieldQuantities,
                        groupingKeys: allowedGroupingWithoutObjectType,
                        caseGanttSettings: [CaseGanttSetting.Duration],
                    });

                    if (!temp)
                        return;

                    settings.set(viewSettingsInitialization(session, temp, undefined, {
                        statistics: graphKpiControlsGetAllowedStatistics(temp.kpi.selectedKpi, temp.groupingKey, session, settings),
                    }));
                },
                controller: <Controls
                    flavor={ControlFlavor.CaseGanttControls}
                />,
                statsSpotlightId: "Timings-CaseGantt-Statistics" + (settings.selection.case === undefined ? "-NoSelection" : ("-Case" + controllerSuffix)),
                stats: settings.selection.case !== undefined ? <SideStatisticsCaseGantt /> : <LogStats />,
                dimensions: getDimensionParameters(session.projectId, "process/case-gantt", isCaseYieldLabelled ? [Dimensions.Timing, Dimensions.Output] : [Dimensions.Timing]),
                content: <>
                    <CaseGantt />
                    <BackButtonTrayElement />
                </>,
            },
        ]}
    />;
}

export function getStatsSpotlightSuffix(settings: SettingsType, hasObjects: boolean) {

    const statsSpotlightSuffix = (settings.selection.node !== undefined ? "Node" : settings.selection.edge !== undefined ? "Edge" : "NoSelection") +
        ((settings.selection.node || settings.selection.edge) && hasObjects ? "-OC" : "");

    return statsSpotlightSuffix;

}
