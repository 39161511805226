import React, { useContext, useState } from "react";
import { StatsLineGraph } from "./StatsLineGraph";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SessionContext } from "../../contexts/SessionContext";
import { TimePeriodFrequencies } from "../../models/ApiTypes";
import { EventKeys } from "../../models/EventKeys";
import { getCustomKpisEquipmentStats } from "../../utils/DfgUtils";
import { get, isObject } from "lodash";
import { useLineGraphData } from "./LineGraph";
import { KpiDefinition, buildKpiSpotlightId, getKpiDefinition, getUnit } from "../../models/Kpi";
import { StatisticTypes } from "../../models/KpiTypes";
import { getKpiList } from "../../views/kpi-matrix/EquipmentMatrixView";
import { useEquipmentAggregationTimeperiods } from "../../hooks/UseEquipmentAggregationTimeperiods";
import { GroupingKeys } from "../../models/Dfg";
import { WorkplaceMatrixCellProps } from "../matrix/WorkplaceMatrixCell";

export default function EquipmentStatsLineGraph() {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const [chartFrequency, setChartFrequency] = useState<TimePeriodFrequencies | undefined>(undefined);
    const kpiList = getKpiList(session, settings);
    const kpis = kpiList.map(kpiType => kpiType.kpiType);

    const equipmentStatsOptions = { 
        ...getCustomKpisEquipmentStats(settings, session, kpis),
        calculateNodes: true,
        calculateActivityValues: true,
    };

    const selectedCell = settings.selection.matrixElement as WorkplaceMatrixCellProps;

    const [equipmentData, isEquipmentDataLoading] = useEquipmentAggregationTimeperiods({
        ...equipmentStatsOptions,
        frequency: chartFrequency,
        eventKeys: {
            ...session.project?.eventKeys ?? {},
            activityKeysGroup: GroupingKeys.Machine,
        } as EventKeys,
        equipment: selectedCell.workplace?.name ? [selectedCell.workplace?.name] : [],
        sort: ["-timeperiodStartTime"]
    }, {
        disable: chartFrequency === undefined,
    });

    if (selectedCell.workplace?.name === undefined || selectedCell?.kpi === undefined) 
        return null;

    const kpiDefinition = getKpiDefinition(selectedCell?.kpi, {settings, session});
    const kpiStatistic = kpiList.find(kpi => kpi.kpiType === selectedCell?.kpi)?.statistic;
    const unit = getUnit(kpiDefinition?.unit, kpiStatistic);

    if (kpiDefinition === undefined || kpiStatistic === undefined)
        return null;

    const propName = getEquipmentOverTimeProp(kpiDefinition, kpiStatistic);
    const equipmentChartData = useLineGraphData(equipmentData, propName, {scale: unit?.name === "percent" ? 100 : undefined});

    return <StatsLineGraph
        spotlightId={[buildKpiSpotlightId(kpiDefinition.id), "OverTime-Explanation-Workplace"]}
        frequency={chartFrequency}
        setFrequency={setChartFrequency}
        isLoading={isEquipmentDataLoading}
        data={equipmentChartData}
        unit={unit}
        kpi={selectedCell.kpi}
        statistics={kpiStatistic} />;
}

function getEquipmentOverTimeProp(kpiDefinition: KpiDefinition | undefined, statistic: StatisticTypes) {
    if (kpiDefinition === undefined)
        return "";
    if (isObject(kpiDefinition.equipmentOverTimeStatisticsPath))
        return get(kpiDefinition.equipmentOverTimeStatisticsPath, statistic) ?? "";
    return kpiDefinition.equipmentOverTimeStatisticsPath ?? "";
}
