import React, { useContext } from "react";
import { MatrixComparisonSelector } from "../../components/controls/MatrixComparisonSelector";
import { TabbedView } from "../../components/tabbed-view/TabbedView";
import { SetupMatrix } from "../setup/SetupMatrix";
import i18n, { getFirstExistingSpotlight } from "../../i18n";
import { SessionContext } from "../../contexts/SessionContext";
import { KpiSortOrderSwitch } from "../../components/controls/KpiControlPrimitives";
import { MatrixComparison, SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import { viewSettingsInitialization } from "../../utils/Initializers";
import { MatrixSetupStats } from "../../components/matrix/stats/MatrixSetupStats";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { useLocation } from "react-router-dom";
import { SetupGraphView } from "../setup/SetupGraphView";
import { SetupStatistics } from "../setup/SetupStatistics";
import { KpiComparisonControls, getValidKpiComparisonControlSettings } from "../../components/controls/KpiComparisonControls";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { KpiControls, getValidKpiControlSettings } from "../../components/controls/KpiControls";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { useUpload } from "../../hooks/UseUpload";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { KpiMatrixHighlightControls } from "../../components/controls/HighlightControls";
import { ObjectMerger } from "../../utils/ObjectMerger";
import { SetupMatrixCellProps } from "../../components/matrix/SetupMatrixCell";
import { MachineSelector } from "../../components/controls/MachineSelector";

export function SetupAnalysisView() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const location = useLocation();

    const hasPlanningData = session.project?.eventKeysPlan !== undefined;
    const [uploadPlan, isUploadLoading] = useUpload(session.project?.uploadIdPlan ?? "", { disable: session.project?.uploadIdPlan === undefined });
    const allowPlanningComparison = uploadPlan?.meta?.attributes === undefined ? undefined : uploadPlan.meta.attributes.some(a => a.name === session.project?.eventKeys?.product);
    const enabledComparisons = allowPlanningComparison ? [MatrixComparison.None, MatrixComparison.Planning,
        MatrixComparison.AbsoluteDeviation, MatrixComparison.RelativeDeviation] : [MatrixComparison.None];

    return <TabbedView
        enableOnlyTimespan={true}
        subtitle={ <MachineSubtitleDropdown /> }
        breadcrumbs={[{
            label: "common.analyses",
            url: `/projects/${session.projectId}/analyses`,
        }, {
            label: "common.changeover"
        }]}
        pages={[
            {
                tabTitle: "common.setupMatrix",
                tabSlug: "matrix",
                spotlightId: getFirstExistingSpotlight(session, [`Setup-Matrix-${session.project?.settings?.setupMatrixType ?? "goodToGood"}`, "Setup-Matrix"]),
                activator: (preferences) => {
                    const temp = viewSettingsInitialization(session, settings, preferences, {
                        sortBy: [SortByType.Kpi, SortByType.Alphabetical, SortByType.Frequency],
                        kpis: [KpiTypes.SetupGap],
                        statistics: [StatisticTypes.Median],
                    });

                    if (!temp)
                        return;

                    settings.mergeSet(ObjectMerger.mergeObjects([
                        temp,
                        getValidKpiControlSettings(session, settings, { kpis: [KpiTypes.SetupGap], hideStatistics: [StatisticTypes.Sum, StatisticTypes.Variance] }),
                    ]));
                },
                controller: <div className="controls">
                    <KpiControls
                        kpis={[KpiTypes.SetupGap]}
                        hideStatistics={[StatisticTypes.Sum, StatisticTypes.Variance]}
                        spotlightArguments={[session.project?.settings?.setupMatrixType ?? "goodToGood"]}
                    />

                    <KpiMatrixHighlightControls label="common.highlights" />

                    <MatrixComparisonSelector enabledComparisons={enabledComparisons} />


                    <KpiSortOrderSwitch
                        spotlightId={buildControllerSpotlightId(location.pathname, ["SortOrder"])}
                        dropdownOptions={[{
                            label: i18n.t("common.setupTime"),
                            value: SortByType.Kpi,
                        }, {
                            label: i18n.t("common.actionFrequency"),
                            value: SortByType.Frequency,
                        }, {
                            label: i18n.t("common.alphabetical"),
                            value: SortByType.Alphabetical,
                        }]} />
                </div>,
                stats: <MatrixSetupStats />,
                statsSpotlightId: getFirstExistingSpotlight(session, [`Setup-Matrix-Stats-${session.project?.settings?.setupMatrixType ?? "goodToGood"}`, "Setup-Matrix-Stats"]),
                isVisible: true,
                content: <>
                    <SetupMatrix />
                    <BackButtonTrayElement />
                </>,
                selectionTrigger: (settings) => {
                    return (settings.selection.matrixElement as SetupMatrixCellProps)?.transition !== undefined;
                },
            }, {
                tabTitle: "common.setupOperations",
                tabSlug: "events",
                spotlightId: getFirstExistingSpotlight(session, [`Setup-Events-${session.project?.settings?.setupMatrixType ?? "goodToGood"}`, "Setup-Events"]),
                statsSpotlightId: getFirstExistingSpotlight(session, [`Setup-Events-Stats-${session.project?.settings?.setupMatrixType ?? "goodToGood"}`, "Setup-Events-Stats"]),
                stats: <SetupStatistics />,
                selectionTrigger: (settings) => {
                    return settings.selection.setupTransition?.actual.from !== undefined;
                },
                isInitializing: hasPlanningData && (uploadPlan === undefined || isUploadLoading),
                content: <>
                    <SetupGraphView />
                    <BackButtonTrayElement />
                </>,
                activator: (preferences) => {
                    const temp = viewSettingsInitialization(session, settings, preferences, {});

                    if (!temp)
                        return;

                    settings.mergeSet(ObjectMerger.mergeObjects<SettingsType>([
                        temp,
                        getValidKpiComparisonControlSettings(session, settings, { enabledComparisons: allowPlanningComparison ? [KpiComparisons.None, KpiComparisons.Planning] : [KpiComparisons.None] }),
                    ]));
                },
                controller: <div className="controls">
                    {hasPlanningData && <>
                        {allowPlanningComparison !== undefined && <KpiComparisonControls enabledComparisons={allowPlanningComparison ? [KpiComparisons.None, KpiComparisons.Planning] : [KpiComparisons.None]} />}
                        {settings.kpi.comparisons === KpiComparisons.Planning && <>
                            <div className="buttons-2col mtn mbLarge">
                                <button id="button-bars" className={settings.kpiMatrix.useDeviationBars ? "active" : ""} onClick={() => {
                                    settings.mergeSet({ kpiMatrix: { useDeviationBars: true } });
                                }}>
                                    {i18n.t("setupEvents.planningBars")}
                                </button>
                                <button id="button-lines" className={!settings.kpiMatrix.useDeviationBars ? "active" : ""} onClick={() => {
                                    settings.mergeSet({ kpiMatrix: { useDeviationBars: false } });
                                }}>
                                    {i18n.t("setupEvents.planningLines")}
                                </button>
                            </div>

                            <KpiMatrixHighlightControls label="common.highlightDeviation" />

                        </>}
                    </>}
                </div>,
            }
        ]}
    />;
}

function MachineSubtitleDropdown() {
    const settings = useContext(SettingsContext);

    const [showTitle, setShowTitle] = React.useState(true);

    return !settings.kpiMatrix.machineName ? <div className="fixedHeight text">{i18n.t("common.workplaceSelection").toString()}</div>: <>
        {showTitle && <div className="fixedHeight text" onClick={() => setShowTitle(false)} style={{ cursor: "pointer" }}>{settings.kpiMatrix.machineName}
            <svg className="svg-icon xxsmall rotate180 ml"><use xlinkHref="#radix-chevron-up"></use></svg>
        </div>}
        {!showTitle && <div className="fixedHeight"><MachineSelector focusInitially={true} bare={true} onChange={() => setShowTitle(true)} onBlur={() => setShowTitle(true)} /></div>}
    </>;
}