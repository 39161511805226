import { isEqual, replace, sortBy } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { FeatureImportance, FeatureType, GetRootCauseAnalysisResponse, SubReasonType } from "../../models/ApiTypes";
import Spinner from "../../components/spinner/Spinner";
import Toast, { ToastTypes } from "../../components/toast/Toast";
import { RcaType } from "../../contexts/ContextTypes";
import { SessionContext, isOniqEmployee } from "../../contexts/SessionContext";
import { emptyRcaState, getPushedHistory, getRecentRcaByType, KpiSettingsType, RcaSettingsType, SettingsContext, SettingsType, updateRecentRcaByType } from "../../contexts/SettingsContext";
import { LegacyAnalyzedValues } from "../../contexts/ContextTypes";
import { useMountedState } from "../../hooks/UseMounted";
import { useScrollFade } from "../../hooks/UseScrollFade";
import i18n from "../../i18n";
import { Graph, GroupingKeys } from "../../models/Dfg";
import { buildEdgeFilter, buildMachineFilter, buildProductCategoryFilter, buildProductFilter, buildReasonFilter, insertFilterAt, isProductFilter } from "../../utils/FilterBuilder";
import { baseQuantities, quantities } from "../../utils/Quantities";
import useWindowResize from "../../utils/WindowResizeHook";
import { Bar, RcaResultsGraph } from "../../views/rca/RcaResultsGraph";
import { getEdgeFeatures, getReasonFromFeature, hasFeatureSmallValue, isFeatureInSelection, rcaFeatureToSelectionUpdate } from "./RcaUtils";
import { useRcaGraph, useRcaSelectionSyncData, useSyncedRcaSelection } from "./UseRca";
import { EventFilter, FilterOrigins, isFilterEqual } from "../../models/EventFilter";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useStatistics } from "../../hooks/UseStatistics";
import { capitalizeFirst, getHash } from "../../utils/Utils";
import { getShortActivityLabelFromActivityValues } from "../../utils/GroupingUtils";
import { minCasesForAnalysis, submitRcaBySettings } from "./RcaCreationUtils";
import { NotificationService } from "../../components/notification/NotificationService";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { TrayElement } from "../../components/tray/TrayElement";
import { navigateWith } from "../../components/tabbed-view/TabbedView";
import { KpiTypes, SortOrder, StatisticTypes } from "../../models/KpiTypes";
import { Api } from "../../api/Api";
import { useOrderSequenceMachineIds } from "../../hooks/UseOrderSequenceMachines";
import Menu, { MenuItem } from "../../components/menu/Menu";
import { ALL_PRODUCTS } from "../../components/product-dropdown/ProductDropdown";

const maxRcaResults = 20;

export default function RootCauseAnalysisResults(props: {
    type: RcaType,
}) {
    const isMounted = useMountedState();
    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const location = useLocation();
    const navigate = useNavigate();

    const { projectId } = useParams<{
        projectId: string,
        tabSlug: string,
    }>();

    const rca = getRecentRcaByType(props.type, settings);

    const graph = useRcaGraph({ rca });
    const rcaSelectionSyncData = useRcaSelectionSyncData({ rca });

    const [results, setResults] = useState<GetRootCauseAnalysisResponse>();

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollFadeClass = useScrollFade(containerRef);

    // Any time we visit the component or update the RCA, we need to make sure the selection is synchronized
    // with any other selections that might have been set previously independently of the RCA.
    useSyncedRcaSelection({ rca });

    useWindowResize();

    const { trackEvent } = useMatomo();

    const machineFilter = buildMachineFilter(settings.selection.node, false);
    const [machineStats, isMachineStatsLoading] = useStatistics(machineFilter ? [...settings.filters, machineFilter] : [], { disable: !machineFilter });

    // Build attribute filter for reason drilldown
    const selectedColumn = settings.selection.feature?.feature?.failureReasonsFrequency ??
        settings.selection.feature?.feature?.failureReasonsDuration ??
        settings.selection.feature?.feature?.interruptionReasonsFrequency ??
        settings.selection.feature?.feature?.setupReasonsFrequency ??
        settings.selection.feature?.feature?.passChangeReasonsFrequency ??
        settings.selection.feature?.feature?.passChangeReasonsDuration ??
        settings.selection.feature?.feature?.interruptionReasonsDuration ??
        settings.selection.feature?.feature?.setupReasonsDuration;

    const columnName = selectedColumn?.columnValue?.column;
    const columnValue = selectedColumn?.columnValue?.value;
    const reasonFilter = (columnName && columnValue) ?
        buildReasonFilter(getReasonFromFeature(settings.selection.feature?.feature)?.columnValue, false, session) :
        undefined;
    const [reasonStats, isReasonStatsLoading] = useStatistics(reasonFilter ? [...settings.filters, reasonFilter] : [], { disable: !reasonFilter });

    useEffect(() => {
        if (rca?.id !== undefined && rca.status !== undefined) {
            // we do not call this through the cache because
            // the response changes depending on the rca status
            Api.getRootCauseAnalysisResults(rca.id, undefined, false).then((result) => {
                if (!isMounted())
                    return;

                if (result.status === "finished" && result.featureImportance !== undefined && result.featureImportance.length > 0) {
                    NotificationService.hide("rca-started");

                    const validImportances = result.featureImportance.filter((f) => (f.relevance ?? -Infinity) > 0);
                    const sortedImportances = sortBy(validImportances, (f) => f.relevance).reverse();
                    setResults({
                        status: result.status,
                        error: result.error,
                        featureImportance: sortedImportances,
                        request: result.request,
                    });
                }
                else if (result.status !== results?.status)
                    setResults({
                        status: result.status,
                        error: result.error,
                        featureImportance: undefined,
                        request: result.request,
                    });

                updateRecentRcaByType(props.type, settings, {
                    result,
                    status: result.status,
                }, true);
            }).catch((e) => {
                // Check if this is any HTTP 4xx error.
                // This happens if the RCA result belongs to someone other than you,
                // and you don't have access to it. Theoretically it might also have
                // other reasons, but redirecting to the "start new RCA" screen sounds
                // like a reasonable thing to do anyway.

                if (Math.floor((e.response?.status ?? 0) / 100) === 4) {
                    // Take first element, truncate rest
                    const rcas = settings.rcaStates[props.type];
                    const existing = rcas && rcas?.length > 0 ? rcas[0] : emptyRcaState;
                    existing.showResults = false;
                    existing.id = undefined;
                    existing.status = undefined;

                    settings.set({
                        ...settings,
                        filters: existing.rcaFilters ?? settings.filters,
                        filterEditor: {
                            editFilter: undefined,
                            editFilterIndex: undefined,
                            showFilterEditor: false,
                        },
                        rcaStates: {
                            ...settings.rcaStates,
                            [props.type]: [existing],
                        },
                        history: [],
                    });
                }
            });
        }
    }, [getHash(rca)]);

    // Needed to move this up here because we're using a hook and are eventually
    // bailing out in the line below. The orderSequenceDfg is used for the
    // action button that redirects to the workplace comparison.
    const isBottleneckRca = rca.rcaType === RcaType.Bottleneck;
    const availibilityRca = settings.selection.feature?.feature !== undefined && decomposeFeature(settings.selection.feature.feature)?.propertyName === "nodeAvailability";
    const showWorkplaceComparisonButton = isBottleneckRca &&
        settings.selection.feature?.feature !== undefined &&
        decomposeFeature(settings.selection.feature.feature) !== undefined && !availibilityRca;

    const isGroupedByMachine = settings.rca.product === ALL_PRODUCTS;
    const featureName = decomposeFeature(settings.selection.feature?.feature)?.propertyName;
    const orderSequence = settings.selection.feature?.feature?.[featureName ?? ""]?.name;
    const orderSequenceMachineIdsRequest = useOrderSequenceMachineIds(orderSequence, {
        eventFilters: rca.rcaFilters ?? settings.previewFilters ?? settings.filters,
    }, {
        disable: !showWorkplaceComparisonButton || rca.rcaType !== RcaType.Bottleneck || (rca.rcaType === RcaType.Bottleneck && isGroupedByMachine),
    });

    if (!rca)
        return <></>;

    // Generate title for the result chart
    let title = i18n.t("rca.top10");

    if (rca.rcaType === RcaType.Time) {
        // Time
        title = rca.sortOrder === SortOrder.Ascending ? i18n.t("rca.top10Early") : i18n.t("rca.top10Late");
    }

    if (rca.rcaType === RcaType.Quality) {
        // Frequency
        title = rca.quantity === "length" ? i18n.t("rca.top10Length") :
            rca.quantity === "mass" ? i18n.t("rca.top10Mass") :
                i18n.t("rca.top10Count");
        if (rca.analyzedValue === LegacyAnalyzedValues.QualityQuota)
            title += " (" + i18n.t("quality.relativeScrap") + ")";
    }

    if (rca.rcaType === RcaType.Throughput) {
        // Process Path
        title = i18n.t(rca.analyzedValue === LegacyAnalyzedValues.OutputRate ? "rca.top10LowRate" : "rca.top10longCases");

        if (rca.analyzedValue === LegacyAnalyzedValues.OutputRate) {
            title += " (" + i18n.t(quantities.find(q => q.id === settings.quantity)?.name ?? "") + ")";
        }
    }

    if (rca.rcaType === RcaType.ThroughputTime)
        title = i18n.t("rca.top10longCases");

    if (rca.rcaType === RcaType.Bottleneck) {
        title = i18n.t("rca.top10Bottleneck");
    }

    if (rca.drilldown?.node) {
        let placeholder = "rca.top10placeholderDrilldownMachine";

        if (rca.rcaType === RcaType.ThroughputTime)
            placeholder = "rca.top10longCasesDrilldownMachine";

        if (rca.rcaType === RcaType.Throughput)
            placeholder = "rca.top10LowRateDrilldownMachine";

        if (rca.rcaType === RcaType.Quality)
            placeholder = (
                rca.quantity === "length" ? i18n.t("rca.top10ScrapLengthDrilldownMachine") :
                    rca.quantity === "mass" ? i18n.t("rca.top10ScrapMassDrilldownMachine") :
                        i18n.t("rca.top10ScrapPiecesDrilldownMachine")
            );

        if (rca.rcaType === RcaType.Time)
            placeholder = "rca.top10DeviationDrilldownMachine";

        title = i18n.t(placeholder, { drilldown: rca.drilldown.node });
    }

    if (rca.drilldown?.reason) {
        let placeholder = "rca.top10placeholderDrilldownReason";
        const subtimeType = rca.drilldown?.reason?.subtime;
        let subtimeStr = "";

        // Subtime strings
        if (subtimeType === SubReasonType.Setup)
            subtimeStr = "common.setup";

        if (subtimeType === SubReasonType.Failure)
            subtimeStr = "common.failure";

        if (subtimeType === SubReasonType.Interruption)
            subtimeStr = "common.interruption";

        if (subtimeType === SubReasonType.PassChange)
            subtimeStr = "common.passChange";

        // Title strings
        if (rca.rcaType === RcaType.ThroughputTime)
            placeholder = "rca.top10longCasesDrilldownReason";

        if (rca.rcaType === RcaType.Throughput)
            placeholder = "rca.top10LowRateDrilldownReason";

        if (rca.rcaType === RcaType.Quality)
            placeholder = (
                rca.quantity === "length" ? i18n.t("rca.top10ScrapLengthDrilldownReason") :
                    rca.quantity === "mass" ? i18n.t("rca.top10ScrapMassDrilldownReason") :
                        i18n.t("rca.top10ScrapPiecesDrilldownReason")
            );

        if (rca.rcaType === RcaType.Time)
            placeholder = "rca.top10DeviationDrilldownReason";

        subtimeStr = i18n.t(subtimeStr);
        title = i18n.t(placeholder, { reason: rca.drilldown.reason.reason, subtime: subtimeStr });
    }

    // Take at most maxRcaResults features that DON'T have the small value flag set.
    const columnData: Bar<FeatureImportance>[] = (results?.featureImportance ?? []).filter(f => {
        return !hasFeatureSmallValue(f.feature);
    }).slice(0, maxRcaResults).map((f) => {
        const label = getFeatureLabel(f.feature, rca);
        return {
            label: <div className="featureTitle">
                <div className="name">{label[1]}</div>
                <div className="entity">{label[0]}</div>
            </div>,
            value: f.relevance,
            data: f,
        };
    });

    const selectionButtonLabel = [RcaType.Throughput, RcaType.ThroughputTime, RcaType.Bottleneck].includes(props.type) ? i18n.t("workflows.planningDeviation.analyzeProcessGraph") :
        props.type === RcaType.Quality ? i18n.t("workflows.planningDeviation.productQualityDeviationButton") :
            i18n.t("workflows.planningDeviation.productProcessDeviation");

    const isReasonSelected = !!getReasonFromFeature(settings.selection.feature?.feature)?.columnValue?.value;

    let actionTitle = "";
    const actionButtons: MenuItem[] = [];

    const isSomethingSelected = settings.selection.node ||
        settings.selection.edge ||
        settings.selection.category ||
        settings.selection.product ||
        settings.selection.feature?.feature.nodeOccurrence ||
        isReasonSelected;

    const primaryActionPath = generatePath({
        [RcaType.Throughput]: "/projects/:projectId/output/process/dfg",
        [RcaType.ThroughputTime]: "/projects/:projectId/timings/process/dfg",
        [RcaType.Quality]: "/projects/:projectId/quality/process/dfg",
        [RcaType.Time]: "/projects/:projectId/timings/process/deviation",
        [RcaType.Bottleneck]: "/projects/:projectId/output/process/dfg",
        [RcaType.OrgLosses]: "/projects/:projectId/output/process/dfg",
    }[rca.rcaType], { projectId: projectId ?? session.projectId! });

    const analyzedValue = {
        [RcaType.Throughput]: LegacyAnalyzedValues.OutputRate,
        [RcaType.ThroughputTime]: LegacyAnalyzedValues.TimeValuesMean,
        [RcaType.Quality]: rca.analyzedValue,
        [RcaType.Time]: LegacyAnalyzedValues.TimeValuesMean,
        [RcaType.Bottleneck]: LegacyAnalyzedValues.OutputRate,
        [RcaType.OrgLosses]: LegacyAnalyzedValues.TimeValuesMean,
    }[rca.rcaType];

    const kpiSettings: KpiSettingsType | undefined = analyzedValue ? {
        ...settings.kpi,
        analyzedValue
    } : undefined;

    const quantity = [RcaType.Throughput, RcaType.Quality].includes(props.type) ? rca.quantity ?? settings.quantity : settings.quantity;

    // Organizational losses drilldown
    if (rca.rcaType === RcaType.OrgLosses) {
        if (settings.selection.feature?.feature.interruptionReasonsDuration === undefined) {
            if (isDrilldown(rca.rcaType, settings))
                actionTitle = "rca.organizationalLosses.drilldownButtonLabelNoNode";
        } else
        if (rcaSupportsDrilldown(RcaType.OrgLosses, settings)) {
            actionTitle = i18n.t("workflows.reasons.rcaButtonTitle", { reason: settings.selection.feature.feature.interruptionReasonsDuration?.name });
            actionButtons.push({
                id: "action-weekdays-drilldown",
                onClick: async () => {
                    const prev = getRecentRcaByType(rca.rcaType, settings);
                    const next: RcaSettingsType = {
                        ...prev!,
                        sortOrder: SortOrder.Ascending,
                        rcaType: rca.rcaType,
                        rcaFilters: (prev?.rcaFilters ?? []),
                        status: undefined,
                        showResults: true,
                        drilldown: {
                            reason: {
                                reason: settings.selection.feature!.feature.interruptionReasonsDuration!.name,
                                subtime: SubReasonType.Interruption,
                            }
                        }
                    };

                    const nextState = {
                        rcaStates: {
                            ...settings.rcaStates,
                            [rca.rcaType]: [...(settings.rcaStates[rca.rcaType] ?? []), next],
                        },
                        selection: {},
                        history: getPushedHistory(location.pathname, settings),
                    };

                    settings.set(nextState);
                    setResults(undefined);

                    await submitRcaBySettings(session, { ...settings, ...nextState }, next, trackEvent);

                },
                title: "shortcuts.showWeekdayAnalysis",
            });
        }
    }

    // Bottleneck drilldown
    if (rca.rcaType === RcaType.Bottleneck && rcaSupportsDrilldown(RcaType.Bottleneck, settings)) {
        if (settings.selection.feature?.feature.nodeOccurrence === undefined) {
            actionTitle = i18n.t(`rca.bottleneck.${isGroupedByMachine ? "drilldownButtonLabelNoMachine" : "drilldownButtonLabelNoOrderSequence"}`);
            actionButtons.push({
                id: "action-bottleneck-drilldown",
                disabled: true,
                title: "rca.bottleneck.drilldownButton",
            });
        }

        if (settings.selection.feature?.feature.nodeOccurrence !== undefined) {
            const labels = getFeatureLabel(settings.selection.feature?.feature, rca);
            actionTitle = i18n.t("rca.bottleneck.drilldownButtonLabel", { orderSequence: labels[1], description: labels[0] });
            actionButtons.push({
                id: "action-bottleneck-drilldown",
                onClick: async () => {
                    const prev = getRecentRcaByType(rca.rcaType, settings);
                    const next: RcaSettingsType = {
                        ...prev!,
                        sortOrder: SortOrder.Ascending,
                        rcaType: rca.rcaType,
                        rcaFilters: (prev?.rcaFilters ?? []),
                        status: undefined,
                        showResults: true,
                        drilldown: {
                            node: settings.selection.feature?.feature.nodeOccurrence?.nodeValues?.activity,
                        }
                    };

                    const nextState = {
                        rcaStates: {
                            ...settings.rcaStates,
                            [rca.rcaType]: [...(settings.rcaStates[rca.rcaType] ?? []), next],
                        },
                        selection: {},
                        history: getPushedHistory(location.pathname, settings),
                    };

                    settings.set(nextState);
                    setResults(undefined);

                    await submitRcaBySettings(session, { ...settings, ...nextState }, next, trackEvent);
                },
                title: "rca.bottleneck.drilldownButton",
            });
        }
    }

    if (settings.selection.edge && rca.rcaType !== RcaType.Bottleneck) {
        actionTitle = "workflows.throughputVariance.analyzeSelectedEdge";
        actionButtons.push({
            id: "action-edge",
            onClick: () => {
                const fromNode = graph?.nodes.find(n => n.id === settings.selection.edge?.from);
                const toNode = graph?.nodes.find(n => n.id === settings.selection.edge?.to);

                if (!fromNode || !toNode)
                    return;

                const filter = buildEdgeFilter(fromNode, toNode, false, GroupingKeys.Machine);
                if (filter)
                    navigateWith(settings, navigate, {
                        ...insertFilterAt(settings, filter, -1),
                        groupingKey: GroupingKeys.Machine,
                        kpi: kpiSettings,
                        quantity: quantity,
                        history: getPushedHistory(location.pathname, settings)
                    }, primaryActionPath);
            },
            title: selectionButtonLabel,
        });
    }

    if (settings.selection.category && settings.selection.categoryValue) {
        actionTitle = i18n.t("workflows.planningDeviation.analyzeSpecificCategory", {
            categoryName: settings.selection.category,
            categoryValue: settings.selection.categoryValue,
        });

        actionButtons.push({
            id: "action-category",
            onClick: () => {
                const filter = buildProductCategoryFilter(settings.selection.category!, [settings.selection.categoryValue!], false, session);
                if (filter)
                    navigateWith(settings, navigate, {
                        ...insertFilterAt(settings, filter, -1),
                        groupingKey: GroupingKeys.Machine,
                        kpi: kpiSettings,
                        quantity: quantity,
                        history: getPushedHistory(location.pathname, settings),
                    }, primaryActionPath);
            },
            title: selectionButtonLabel,
        });
    }

    if (settings.selection.product && rca.rcaType !== RcaType.Bottleneck) {
        actionTitle = i18n.t("workflows.planningDeviation.analyzeProduct", {
            productName: settings.selection.product.name
        });

        actionButtons.push({
            id: "action-product",
            onClick: () => {
                const filter = buildProductFilter(settings.selection.product, false, session);
                if (filter)
                    navigateWith(settings, navigate, {
                        ...insertFilterAt(settings, filter, -1),
                        groupingKey: GroupingKeys.Machine,
                        kpi: kpiSettings,
                        quantity: quantity,
                        history: getPushedHistory(location.pathname, settings),
                    }, primaryActionPath);
            },
            title: selectionButtonLabel,
        });
    }

    if (isReasonSelected && rca.rcaType !== RcaType.Bottleneck && rca.rcaType !== RcaType.OrgLosses) {
        actionTitle = i18n.t("workflows.reasons.rcaButtonTitle", {
            reason: getReasonFromFeature(settings.selection.feature?.feature)?.columnValue?.value
        });
        actionButtons.push({
            id: "action-reason",
            onClick: () => {
                const filter = buildReasonFilter(getReasonFromFeature(settings.selection.feature?.feature)?.columnValue, false, session);
                if (filter)
                    navigateWith(settings, navigate, {
                        ...insertFilterAt(settings, filter, -1),
                        groupingKey: GroupingKeys.Machine,
                        kpi: kpiSettings,
                        quantity: quantity,
                        history: getPushedHistory(location.pathname, settings)
                    }, primaryActionPath);
            },
            title: selectionButtonLabel,
        });

        // Add drilldown button if we haven't drilled down yet
        if (rcaSupportsDrilldown(rca.rcaType, settings))
            actionButtons.push({
                id: "action-reason-drilldown",
                disabled: isReasonStatsLoading || (reasonStats.numFilteredTraces ?? 0) < minCasesForAnalysis,
                isLoading: isReasonStatsLoading,
                onClick: async () => {
                    if (!reasonFilter)
                        return;

                    reasonFilter.origin = FilterOrigins.Rca;

                    const feature = getReasonFromFeature(settings.selection.feature?.feature);

                    let subReasonType = undefined;
                    if (
                        settings.selection.feature?.feature.failureReasonsDuration !== undefined
                        || settings.selection.feature?.feature.failureReasonsFrequency !== undefined
                    )
                        subReasonType = SubReasonType.Failure;

                    if (
                        settings.selection.feature?.feature.setupReasonsDuration !== undefined
                        || settings.selection.feature?.feature.setupReasonsFrequency !== undefined
                    )
                        subReasonType = SubReasonType.Setup;

                    if (
                        settings.selection.feature?.feature.interruptionReasonsDuration !== undefined
                        || settings.selection.feature?.feature.interruptionReasonsFrequency !== undefined
                    )
                        subReasonType = SubReasonType.Interruption;

                    if (
                        settings.selection.feature?.feature.passChangeReasonsDuration !== undefined
                        || settings.selection.feature?.feature.passChangeReasonsFrequency !== undefined
                    )
                        subReasonType = SubReasonType.PassChange;

                    const prev = getRecentRcaByType(rca.rcaType, settings);
                    const next: RcaSettingsType = {
                        ...prev!,
                        sortOrder: SortOrder.Ascending,
                        rcaType: rca.rcaType,
                        rcaFilters: [...(prev?.rcaFilters ?? []), reasonFilter],
                        status: undefined,
                        showResults: true,
                        drilldown: {
                            reason: {
                                subtime: subReasonType,
                                reason: feature?.columnValue?.value
                            }
                        }
                    };

                    const nextState = {
                        ...insertFilterAt(settings, reasonFilter, -1),
                        rcaStates: {
                            ...settings.rcaStates,
                            [rca.rcaType]: [...(settings.rcaStates[rca.rcaType] ?? []), next],
                        },
                        selection: {},
                        history: getPushedHistory(location.pathname, settings),
                    };

                    settings.set(nextState);
                    setResults(undefined);

                    await submitRcaBySettings(session, { ...settings, ...nextState }, next, trackEvent);
                },
                title: "rca.startDrilldown"
            });
    }

    if (isBottleneckRca) {

        const machineName = isGroupedByMachine ? settings.selection.feature?.feature?.[decomposeFeature(settings.selection.feature?.feature)?.propertyName ?? ""]?.nodeValues?.machine :
            orderSequenceMachineIdsRequest.machineNames?.length === 1 ? orderSequenceMachineIdsRequest.machineNames[0] : "";

        const machineId = isGroupedByMachine ? [settings.selection.feature?.feature?.[decomposeFeature(settings.selection.feature?.feature)?.propertyName ?? ""]?.nodeValues?.activity] :
            orderSequenceMachineIdsRequest.machineIds ?? [];

        // Button "Show setup matrix": This one is for nodeBusyTime features
        if ((session.project?.features?.allowSetupMatrix || isOniqEmployee(session)) &&
            session.project?.eventKeys?.product !== undefined &&
            (orderSequenceMachineIdsRequest.machineNames?.length === 1 || isGroupedByMachine) &&
            session.project.eventKeys.passId !== undefined) {
            actionButtons.push({
                id: "action-redirect-setup-matrix",
                disabled: !isGroupedByMachine && orderSequenceMachineIdsRequest.isLoading,
                onClick: async () => {
                    navigateWith(settings, navigate, {
                        kpiMatrix: {
                            ...settings.kpiMatrix,
                            machineName: machineName,
                        },
                        history: getPushedHistory(location.pathname, settings),
                    }, `/projects/${projectId}/analyses/setup/matrix`);
                },
                isLoading: !isGroupedByMachine && orderSequenceMachineIdsRequest.isMachineNamesLoading,
                title: "setupMatrix.showSetupMatrix",
            });
        }

        // Button "Show workplace comparison": Visible for all indicators except availibility indicator
        if (showWorkplaceComparisonButton) {
            // Get list of machines that are used in the selected order sequence
            actionButtons.push({
                id: "action-redirect-workplace-comparison",
                disabled: !isGroupedByMachine && orderSequenceMachineIdsRequest.isLoading,
                onClick: async () => {
                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                        kpiMatrix: {
                            ...settings.kpiMatrix,
                            machines: machineId,
                        }
                    }, `/projects/${projectId}/analyses/workplaces/comparison`);
                },
                isLoading: !isGroupedByMachine && orderSequenceMachineIdsRequest.isLoading,
                title: "shortcuts.showWorkplaceComparison",
            });
        }

        if (availibilityRca) {
            actionButtons.push({
                id: "action-redirect-equipment-comparison",
                disabled: !isGroupedByMachine && orderSequenceMachineIdsRequest.isLoading,
                isHidden: !(session.project?.features?.allowEquipmentComparison || isOniqEmployee(session)),
                onClick: async () => {
                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                        kpiMatrix: {
                            ...settings.kpiMatrix,
                            machines: machineId,
                        }
                    }, `/projects/${projectId}/analyses/equipments/comparison`);
                },
                isLoading: !isGroupedByMachine && orderSequenceMachineIdsRequest.isLoading,
                title: "shortcuts.showEquipmentComparison",
            });
        }
    }

    if (rca.rcaType === RcaType.Bottleneck &&
        settings.selection.feature) {

        const description = settings.selection.feature?.feature?.[decomposeFeature(settings.selection.feature?.feature)?.propertyName ?? ""]?.nodeValues?.operation;
        actionTitle = i18n.t("rca.bottleneck.analyzeForOrderSequence", {
            groupingBy: isGroupedByMachine ? "" : i18n.t("common.workSequence") + " ",
            identifier: settings.selection.feature?.feature?.[decomposeFeature(settings.selection.feature?.feature)?.propertyName ?? ""]?.nodeValues?.[isGroupedByMachine ? "machine" : "passId"],
            description: description ? ", " + description : "",
        });

        // Show bottleneck in value stream
        actionButtons.push({
            id: "action-redirect-valuestream",
            onClick: async () => {
                const id = getNodeIdFromFeature(settings.selection.feature?.feature);
                const featureData = decomposeFeature(settings.selection.feature?.feature);
                let machineFilter: EventFilter | undefined;

                if (!featureData || !id)
                    return;

                if (isGroupedByMachine){
                    const nodeSelected = graph?.nodes.find(node => node.id === id);
                    machineFilter = buildMachineFilter(nodeSelected, false);
                }

                navigateWith(settings, navigate, {
                    history: getPushedHistory(location.pathname, settings),
                    groupingKey: isGroupedByMachine ? GroupingKeys.Machine : GroupingKeys.PassValueStream,
                    kpi: {
                        ...settings.kpi,
                        selectedKpi: featureData.kpi,
                        statistic: featureData.statistic,
                    },
                    quantity: featureData?.quantity ?? settings.quantity,
                    selection: { node: { id } },
                    // For large DFG when grouping by machine sometimes the node is not visible in the graph so we should filter to the selected machine to be able to show it in DFG
                    ...(isGroupedByMachine ? { filters: (settings.filters ?? []).filter(f => !isFilterEqual(f, machineFilter)).concat(machineFilter ? [machineFilter] : []) } : {}),
                }, `/projects/${projectId}${featureData.dimension}`);
            },
            title: "rca.bottleneck.goToValueStream",
        });

        // Start cycle time analysis when we're in a drilldown
        if (rca.drilldown !== undefined && !isGroupedByMachine)
            actionButtons.push({
                disabled: isGroupedByMachine,
                id: "action-redirect-cycle-analysis",
                onClick: async () => {
                    const id = getNodeIdFromFeature(settings.selection.feature?.feature);
                    if (id === undefined)
                        return;

                    navigateWith(settings, navigate, {
                        history: getPushedHistory(location.pathname, settings),
                        groupingKey: GroupingKeys.PassValueStream,
                        selection: { node: { id } },
                    }, `/projects/${projectId}/analyses/cycle-time/summary`);
                },
                title: "rca.bottleneck.startCycleTimeAnalysis",
            });
    }

    if (rca.rcaType !== RcaType.Bottleneck && settings.selection.node) {
        if (settings.selection.node.activityValues?.machine?.value !== undefined) {
            actionTitle = i18n.t("workflows.planningDeviationByMachine.analyzeSpecificMachine", {
                machine: getShortActivityLabelFromActivityValues(settings.selection.node.activityValues, GroupingKeys.Machine),
            });

            actionButtons.push({
                id: "action-machine",
                onClick: () => {
                    const filter = buildMachineFilter(settings.selection.node!, false);
                    if (filter)
                        navigateWith(settings, navigate, {
                            ...insertFilterAt(settings, filter, -1),
                            groupingKey: GroupingKeys.Machine,
                            kpi: kpiSettings,
                            quantity: quantity,
                            history: getPushedHistory(location.pathname, settings)
                        }, primaryActionPath);
                },
                title: selectionButtonLabel,
            });
        }

        // Add drilldown button for throughput analysis if we haven't drilled down yet
        if (rcaSupportsDrilldown(rca.rcaType, settings))
            actionButtons.push({
                id: "action-machine-drilldown",
                disabled: isMachineStatsLoading || (machineStats.numFilteredTraces ?? 0) < minCasesForAnalysis,
                onClick: async () => {
                    if (!machineFilter)
                        return;

                    machineFilter.origin = FilterOrigins.Rca;

                    const prev = getRecentRcaByType(rca.rcaType, settings);
                    const next: RcaSettingsType = {
                        ...prev!,
                        sortOrder: SortOrder.Ascending,
                        rcaType: rca.rcaType,
                        rcaFilters: [...(prev?.rcaFilters ?? []), machineFilter],
                        status: undefined,
                        showResults: true,
                        drilldown: {
                            node: settings.selection.node!.id
                        }
                    };

                    const nextState = {
                        ...insertFilterAt(settings, machineFilter, -1),
                        rcaStates: {
                            ...settings.rcaStates,
                            [rca.rcaType]: [...(settings.rcaStates[rca.rcaType] ?? []), next],
                        },
                        selection: {},
                        history: getPushedHistory(location.pathname, settings),
                    };

                    settings.set(nextState);
                    setResults(undefined);

                    await submitRcaBySettings(session, { ...settings, ...nextState }, next, trackEvent);
                },
                isLoading: isMachineStatsLoading,
                title: "rca.startDrilldown"
            });
    }

    const showSpinner = rcaSelectionSyncData === undefined ||
        (getRecentRcaByType(props.type, settings)?.status !== "failed" &&
            getRecentRcaByType(props.type, settings)?.status !== "finished");
    
    const bottleneckResultsExplainer = isGroupedByMachine ? "resultsExplainerMachine" : "resultsExplainerOrderSequence";
    const bottleneckDrilldownExplainer = isGroupedByMachine ? "drilldownExplainerMachine" : "drilldownExplainerOrderSequence";

    const explainerKey = `rca.${{
        [RcaType.Time]: "time",
        [RcaType.Quality]: "quality",
        [RcaType.Throughput]: "throughput",
        [RcaType.ThroughputTime]: "throughputTime",
        [RcaType.Bottleneck]: "bottleneck",
        [RcaType.OrgLosses]: "organizationalLosses",
    }[rca.rcaType]}.${isDrilldown(rca.rcaType, settings) ? `${rca.rcaType === RcaType.Bottleneck ? bottleneckResultsExplainer : "resultsExplainer"}` : `${rca.rcaType === RcaType.Bottleneck ? bottleneckDrilldownExplainer : "drilldownExplainer"}`}`;

    const featureKeys = Object.keys(rca.result?.featureImportance?.[0]?.feature ?? {});
    const explainerHtml = i18n.t(explainerKey, {
        identifier: rca.result?.request?.rcaType?.drilldown?.node,

        description: rca.result?.featureImportance?.[0]?.feature?.[featureKeys[0] ?? ""]?.nodeValues?.operation,
        drilldownReason: rca.drilldown?.reason?.reason,

        //Details here is only used for the moment in bottleneck grouped by machine or order sequnece
        details: (isGroupedByMachine ? "" : i18n.t("rca.bottleneck.workSequence") + " ") + [
            rca.result?.featureImportance?.[0]?.feature?.[featureKeys[0] ?? ""]?.nodeValues?.[isGroupedByMachine ? "machine" : "passId"],
            rca.result?.featureImportance?.[0]?.feature?.[featureKeys[0] ?? ""]?.nodeValues?.operation,
        ].filter(e => e !== undefined && e.length > 0).join(" - "),

        product: rca.result?.request?.eventsParams?.eventlogs?.actual?.eventFilters?.map(e => isProductFilter(e, false, session.project?.eventKeys)).find(e => e !== undefined && e.length > 0),
    }).toString();

    const hasExplainer = explainerHtml !== explainerKey;
    const selectedIndex = columnData?.findIndex(c => c.data ? isFeatureInSelection(c.data, settings.selection) : false);

    return <div className="rootCauseAnalysis">
        <Spinner isLoading={showSpinner} />
        {!showSpinner && <>
            {hasExplainer && <div className="mbl infoText" dangerouslySetInnerHTML={{ __html: explainerHtml }} />}
            {!hasExplainer && <div />}
            <div ref={containerRef} className={"scroll" + scrollFadeClass}>
                <Toast visible={results?.status !== undefined && results?.status !== "started" && !isEqual(rca.rcaFilters, settings.filters)} type={ToastTypes.Info}>
                    {i18n.t("rca.filtersChanged")}
                </Toast>
                {results?.status === "failed" && <button className="shortcutButton" onClick={() => createNew()}>{i18n.t("rca.createNew")}</button>}
                {columnData !== undefined && results?.status === "finished" && <div>
                    <RcaResultsGraph
                        minBarWidth={1}
                        title={hasExplainer ? undefined : title}
                        data={columnData}
                        selectedIndex={selectedIndex}
                        onSelected={(col) => {
                            if (col === undefined) {
                                settings.setSelection({});
                            }
                            const rcaFeature = col?.data;
                            if (rcaFeature !== undefined && rcaSelectionSyncData !== undefined)
                                settings.setSelection(rcaFeatureToSelectionUpdate(rcaFeature, rcaSelectionSyncData));
                        }}
                    />
                </div>}
            </div>

            <div className="resultButtonArea">
                {results?.status === "finished" && rca.id !== undefined && <>
                    {/* RCA Results available */}
                    <div className="actionButtonSection">
                        <div className="left">
                            {(isSomethingSelected || rca.rcaType === RcaType.Bottleneck || rca.rcaType === RcaType.OrgLosses) && <>
                                <h3>{i18n.t(actionTitle)}</h3>
                                {selectedIndex >= 0 && actionButtons.filter(a => !a.isHidden).length > 0 && <Menu className="menuLight" items={actionButtons}>
                                    <button className="shortcutButton">
                                        {i18n.t("common.actions")}
                                        <svg className="svg-icon tiny rotate180"><use xlinkHref="#collapser" /></svg>
                                    </button>
                                </Menu>}
                            </>}
                        </div>

                        <TrayElement>
                            <button style={{ order: -1001 }} className="shortcutButton" onClick={() => createNew()}>{i18n.t("rca.restart")}</button>
                        </TrayElement>
                        <BackButtonTrayElement />
                    </div>
                </>}
            </div>
        </>}
    </div>;

    function getFeatureLabel(feature: FeatureType, rca: RcaSettingsType): [string, string] {
        const isTime = [RcaType.Time, RcaType.ThroughputTime, RcaType.Throughput].includes(rca.rcaType);

        if (feature.caseAttribute?.columnValue) {
            const isProduct = session.project?.eventKeys?.product === feature.caseAttribute.columnValue.column;
            return [i18n.t(isProduct ? "common.product" : "common.productCategory"), feature.caseAttribute.columnValue.value];
        }

        const isPass = results?.request.eventsParams.eventlogs.actual.eventKeys.activityKeysGroup === GroupingKeys.PassValueStream;
        if (feature.nodeOccurrence?.nodeValues) {
            // Check grouping to decide wether this is a machine or a pass
            if (isPass) {
                // Pass
                const passName = feature.nodeOccurrence?.nodeValues?.activity ?? "";
                return [feature.nodeOccurrence?.nodeValues.operation ?? "", i18n.t("common.workSequence") + " " + passName ?? ""];
            }

            // Machine
            const machineName = getNodeFeatureLabel(feature.nodeOccurrence?.nodeValues?.activity, graph);
            return [i18n.t("common.machine"), machineName ?? ""];
        }

        if (feature.timeUsage?.columnValue?.column)
            return getTimeCategoryFeatureLabel(feature.timeUsage?.columnValue?.column);

        if (feature.absoluteTimeUsage?.columnValue?.column)
            return getTimeCategoryFeatureLabel(feature.absoluteTimeUsage?.columnValue?.column);

        if (feature.frequencyUsage?.columnValue?.column)
            return getTimeCategoryFeatureLabel(feature.frequencyUsage?.columnValue?.column);

        const noReasonSupplied = i18n.t("common.noReasonSupplied");

        // Sub time columns
        const subTimeColumn = [
            "interruptionReasonsDuration",
            "passChangeReasonsDuration",
            "setupReasonsDuration",
            "failureReasonsDuration",
            "passChangeReasonsFrequency",
            "interruptionReasonsFrequency",
            "setupReasonsFrequency",
            "failureReasonsFrequency",
        ].find(c => feature[c]?.columnValue?.column !== undefined);
        if (subTimeColumn !== undefined) {
            // For fixing issue https://gitlab.com/ONIQofficial/general/oniq/-/issues/2696,
            // we're trying to translate the columnValue, and if that fails, return the
            // value as-is.
            const columnValue = feature[subTimeColumn]?.columnValue?.value;
            const translationKey = "rca.columnValueReplacements." + (columnValue ?? "");
            const translatedValue = i18n.t(translationKey);

            const translatedColumnValue = translatedValue === translationKey ? columnValue : translatedValue;

            return [
                i18n.t(`rca.namePrefixes.${subTimeColumn}`),
                translatedColumnValue ?? noReasonSupplied,
            ];
        }

        const nodeColumns = [{
            value: feature.nodeDuration?.nodeValues?.activity,
            prefix: "nodePassTime",
        }, {
            value: feature.nodeSetupTime?.nodeValues?.activity,
            title: "rca.namePrefixes.machineSetupTime"
        }, {
            value: feature.nodeFailureTime?.nodeValues?.activity,
            title: "rca.namePrefixes.machineFailureTime"
        }, {
            value: feature.nodeInterruptionTime?.nodeValues?.activity,
            title: "rca.namePrefixes.machineInterruptionTime"
        }, {
            value: feature.nodePassChangeTime?.nodeValues?.activity,
            title: "rca.namePrefixes.machinePassChangeTime"
        }, {
            value: feature.nodeProductionTime?.nodeValues?.activity,
            title: "rca.namePrefixes.machineProductionTime"
        }, {
            value: feature.nodePassTime?.nodeValues?.activity,
            prefix: "nodePassTime",
        }, {
            value: feature.nodeBusyTime?.nodeValues?.activity,
            prefix: "busyTime",
        }, {
            value: feature.nodeCycleTimeMass?.nodeValues?.activity,
            prefix: "cycleTime",
        }, {
            value: feature.nodeCycleTimeLength?.nodeValues?.activity,
            prefix: "cycleTime",
        }, {
            value: feature.nodeCycleTimeCount?.nodeValues?.activity,
            prefix: "cycleTime",
        }, {
            value: feature.nodeAverageYieldStockMass?.nodeValues?.activity,
            prefix: "yieldStock",
        }, {
            value: feature.nodeAverageYieldStockLength?.nodeValues?.activity,
            prefix: "yieldStock",
        }, {
            value: feature.nodeAverageYieldStockCount?.nodeValues?.activity,
            prefix: "yieldStock",
        }, {
            value: feature.nodeDiffYieldRateMass?.nodeValues?.activity,
            prefix: "yieldRate",
        }, {
            value: feature.nodeDiffYieldRateLength?.nodeValues?.activity,
            prefix: "yieldRate",
        }, {
            value: feature.nodeDiffYieldRateCount?.nodeValues?.activity,
            prefix: "yieldRate",
        }, {
            value: feature.nodeStockRange?.nodeValues?.activity,
            prefix: "stockRange",
        }, {
            value: feature.nodeAvailability?.nodeValues?.activity,
            prefix: "availability",
        }];

        for (const column of nodeColumns)
            if (column.value !== undefined && (column.title || column.prefix))
                return [
                    column.prefix ? i18n.t(`rca.bottleneck.${column.prefix}Description`) : i18n.t(column.title!),
                    column.prefix ? i18n.t(`rca.bottleneck.${column.prefix}Title`) : column.value,
                ];

        // Reasons per machine features
        const reasonsPerNodeColumn = [
            "interruptionReasonsDurationPerNode",
            "failureReasonsDurationPerNode",
            "setupReasonsDurationPerNode",
            "passChangeReasonsDurationPerNode",
            "maintenanceReasonsDurationPerNode",
            "interruptionReasonsFrequencyPerNode",
            "failureReasonsFrequencyPerNode",
            "setupReasonsFrequencyPerNode",
            "passChangeReasonsFrequencyPerNode",
            "maintenanceReasonsFrequencyPerNode",
        ].find(c => feature[c]?.columnValue?.value !== undefined);
        if (reasonsPerNodeColumn !== undefined)
            return [
                i18n.t("common.machine"),
                feature[reasonsPerNodeColumn]!.columnValue!.value,
            ];

        for (const edgeFeature of getEdgeFeatures(feature)) {
            if (edgeFeature)
                return [i18n.t(isTime ? "common.transitionTime" : "common.statistics.freqEdge"),
                    (isPass ? edgeFeature?.edge.fromNode.activity :
                        getNodeFeatureLabel(edgeFeature?.edge.fromNode.activity, graph)) + " ➞ " +
                (isPass ? edgeFeature?.edge.toNode.activity :
                    getNodeFeatureLabel(edgeFeature?.edge.toNode.activity, graph))
                ];
        }

        return ["?", "?"];
    }

    function createNew(showResults = false) {
        const rcas = settings.rcaStates[props.type];

        // Take first element, truncate rest
        const existing = rcas && rcas?.length > 0 ? rcas[0] : emptyRcaState;
        existing.showResults = showResults;

        settings.set({
            ...settings,
            filters: settings.filters.filter(f => f.origin !== FilterOrigins.Rca),
            filterEditor: {
                editFilter: undefined,
                editFilterIndex: undefined,
                showFilterEditor: false,
            },
            rcaStates: {
                ...settings.rcaStates,
                [props.type]: [existing],
            },
            history: [],
        });
    }
}

function getNodeFeatureLabel(activity: string, graph: Graph | undefined) {
    const node = graph?.nodes.find(node => node.id === activity);
    const machineName = getShortActivityLabelFromActivityValues(node?.activityValues, GroupingKeys.Machine);
    return machineName;
}

function getTimeCategoryFeatureLabel(column: string): [string, string] {
    const normalizedColumn = replace(column, "_", "").toLowerCase();
    const labelMap: { [id: string]: string } = {
        production: "common.busyTime",
        process: "common.busyTime",
        setup: "common.busyTime",
        busy: "common.busyTime",
        interruption: "common.busyTime",
        failure: "common.busyTime",
        passchange: "common.passTime",
        pass: "common.passTime",
        unknown: "common.passTime",
    };
    const categoryLabel = i18n.t(labelMap[normalizedColumn] ?? "rca.namePrefixes.timeComponent");
    const columnLabel = i18n.t(`common.${normalizedColumn}`);
    return [categoryLabel, columnLabel];
}


function rcaSupportsDrilldown(rcaType: RcaType, settings: SettingsType) {
    const rcaState = settings.rcaStates[rcaType];

    if (rcaState === undefined)
        return false;

    // an rcaState of length 2 would indicate that there is already a drilldown in which case we do
    // not want to drill down further
    return !rcaState || isDrilldown(rcaType, settings);
}

function isDrilldown(rcaType: RcaType, settings: SettingsType) {
    const rcaState = settings.rcaStates[rcaType];

    if (rcaState === undefined)
        return false;

    return rcaState.length < 2;
}

function getNodeIdFromFeature(feature: FeatureType | undefined) {
    let id: string | undefined = undefined;
    for (const prop of Object.keys(feature ?? {}) as (keyof FeatureType)[])
        if ((id = feature![prop]?.nodeValues?.activity) !== undefined)
            break;

    return id;
}

/**
 * Returns the settings that should be used for navigation.
 * Depending on the feature different settings are set for
 * the kpi, path (dimension), statistic and quantity.
 * @param feature
 * @returns
 */
function decomposeFeature(feature: FeatureType | undefined) {
    if (!feature)
        return undefined;

    const kpiMap = [{
        kpi: KpiTypes.ProductionTime,
        prefix: "nodeCycleTime",
        statistic: StatisticTypes.Mean,
        isQuantityDependent: true,
        dimension: "/timings/process/dfg",
    }, {
        kpi: KpiTypes.ThroughputRate,
        prefix: "nodeDiffYieldRate",
        isQuantityDependent: true,
        statistic: StatisticTypes.Mean,
        dimension: "/output/process/dfg",
    }, {
        kpi: KpiTypes.WorkInProcessInventory,
        prefix: "nodeAverageYieldStock",
        statistic: StatisticTypes.Sum,
        isQuantityDependent: true,
        dimension: "/stock/process/dfg",
    }, 
    {
        kpi: KpiTypes.WorkInProcessInventory,
        prefix: "nodeStockRange",
        statistic: StatisticTypes.Sum,
        dimension: "/stock/process/dfg",
    }, {
        kpi: KpiTypes.BusyTime,
        statistic: StatisticTypes.Mean,
        prefix: "nodeBusyTime",
        dimension: "/timings/process/dfg",
    }, {
        kpi: KpiTypes.ThroughputTime,
        prefix: "nodeDuration",
        statistic: StatisticTypes.Sum,
        dimension: "/timings/process/dfg",
    }, {
        kpi: KpiTypes.BusyTime,
        prefix: "nodeOccurrence",
        statistic: StatisticTypes.Mean,
        dimension: "/timings/process/dfg",
    },
    {
        kpi: KpiTypes.Availability,
        prefix: "nodeAvailability",
        statistic: StatisticTypes.Mean,
        dimension: "/timings/process/dfg",
    }];

    for (const element of kpiMap) {
        if (!element.isQuantityDependent) {
            if (feature[element.prefix])
                return {
                    propertyName: element.prefix,
                    kpi: element.kpi,
                    statistic: element.statistic,
                    dimension: element.dimension,
                };
            continue;
        }

        // Try to retrieve quantity as well
        for (const quantity of baseQuantities) {
            const key = element.prefix + capitalizeFirst(quantity);
            if (feature[key])
                return {
                    propertyName: key,
                    kpi: element.kpi,
                    statistic: element.statistic,
                    quantity,
                    dimension: element.dimension,
                };
        }
    }

    return undefined;
}
