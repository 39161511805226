import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { capitalize, flatMap, get, isBoolean, noop, uniqBy } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactSwitch from "react-switch";
import Global from "../../Global";
import colors from "../../colors.json";
import { DashboardTile } from "../../components/dashboard-tile/DashboardTile";
import FilterEditor from "../../components/filter-editor/FilterEditor";
import Menu from "../../components/menu/Menu";
import { IModal } from "../../components/modal/Modal";
import { NoDataAvailable } from "../../components/no-data-available/NoDataAvailable";
import { NotificationService } from "../../components/notification/NotificationService";
import Spinner from "../../components/spinner/Spinner";
import { ValueSpinner } from "../../components/value-spinner/ValueSpinner";
import { SessionContext, SessionType } from "../../contexts/SessionContext";
import { DashboadTileSettings, DashboardSettingsType, SettingsContext, SettingsContextType, SettingsType } from "../../contexts/SettingsContext";
import { useDeviationTimeperiodStatistics } from "../../hooks/UseDeviationTimeperiodStatistics";
import { toGridLayout, useGridLayout } from "../../hooks/UseGridLayout";
import { useStatistics } from "../../hooks/UseStatistics";
import { useTimeAggregatedCaseStatistics } from "../../hooks/UseTimeAggregatedCaseStatistics";
import i18n from "../../i18n";
import { CustomKpi, PerTimeperiodDeviationStatisticsSchema, PerTimeperiodStatisticsSchema, StatsCalculationRequest, TimePeriodFrequencies, TimeperiodCaseAggregationStatisticsSchema, TimeperiodDeviationStatisticsSchema, disableAllCalcOptions } from "../../models/ApiTypes";
import { Point } from "../../models/Dfg";
import { KpiDefinition, TimeperiodApis, getKpiDefinition, getProductStatisticPath, getTimeperiodStatisticPath, getUnit } from "../../models/Kpi";
import { KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import { Formatter, UnitMetadata, getLongUnit } from "../../utils/Formatter";
import { ShareModal, shareAsync } from "../../utils/Share";
import { addStep, floorTime, timestampSort, toUserTimezone, toUserTimezoneMillis } from "../../utils/TimezoneUtils";
import updateUserpilotUrl from "../../utils/Userpilot";
import { EditFavoritesModal } from "../favorites/EditFavoritesModal";
import { DashboardTileSettingsModal, getContextFromTile, getContextOverride } from "./DashboardTileSettingsModal";
import { useTimeAggregatedEventStatistics } from "../../hooks/UseTimeAggregatedEventStatistics";
import { classNames } from "../../utils/Utils";

type TileModel = DashboadTileSettings & {
    endpoint: TimeperiodApis,
    kpiDefinition: KpiDefinition,
};

const durationLimits = {
    [TimePeriodFrequencies.Day]: 28,
    [TimePeriodFrequencies.Week]: 24,
    [TimePeriodFrequencies.Month]: 24,
    [TimePeriodFrequencies.Year]: 10,
};

type LoadingState = {
    isLoading: boolean,
    isCaseLoading: boolean,
    isCaseDeviationLoading: boolean,
    isEventLoading: boolean,
}

export function Dashboard() {
    const { projectId } = useParams<{
        projectId: string,
    }>();

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    session.setProject(projectId);

    const [selectedTileIdx, setSelectedTileIdx] = useState<number | undefined>(undefined);

    const { trackEvent } = useMatomo();

    // The dashboard state is primarily stored in the settings context.
    // However, shared views may overwrite these!
    useEffect(() => {
        if (session.project === undefined)
            return;

        // Settings don't have dashboard data, so we need to get it
        // from the local storage
        if (settings.dashboard === undefined) {
            const dashboardSettings = getDashboardSettings(session, projectId);
            if (dashboardSettings)
                settings.mergeSet({
                    dashboard: dashboardSettings,
                });
            return;
        }

        // Update local storage tile settings
        if (settings.dashboard.tiles)
            writeDashboardSettings(session, settings);
    }, [
        session.project,
        JSON.stringify(settings.dashboard),
    ]);

    updateUserpilotUrl();

    const [tileEditIdx, setTileEditIdx] = useState<number | undefined>(undefined);
    const [showShareModal, setShowShareModal] = useState(false);

    // Prevent selection of frequencies smaller than this
    const [maxFrequency, setMaxFrequency] = useState<TimePeriodFrequencies>(TimePeriodFrequencies.Year);

    const [allStats, isAllStatsLoading] = useStatistics();

    // We use an empty filter list here because we are refering to the stats of the whole dataset.
    const [stats, isStatsLoading] = useStatistics([], {
        onData: (data) => {
            // Only initialize frequency if previously unset
            if (settings.dashboard?.frequency !== undefined)
                return;

            const durationSeconds = ((data.maxDate?.getTime() ?? 0) - (data.minDate?.getTime() ?? 0)) / 1000;
            if (durationSeconds === 0)
                return;

            const initialFrequencies = [{
                frequency: TimePeriodFrequencies.Year,
                minSeconds: 3 * 365 * 86400,
            }, {
                frequency: TimePeriodFrequencies.Month,
                minSeconds: 3 * 31 * 86400,
            }, {
                frequency: TimePeriodFrequencies.Week,
                minSeconds: 3 * 7 * 86400,
            }];

            let initialFrequency = TimePeriodFrequencies.Day;
            for (const f of initialFrequencies)
                if (durationSeconds > f.minSeconds) {
                    initialFrequency = f.frequency;
                    break;
                }

            queueMicrotask(() => {
                settings.mergeSet({
                    dashboard: {
                        frequency: initialFrequency,
                    },
                });
            });
        }
    });

    const hasPlanningData = session.project?.eventKeysPlan !== undefined;

    // make stable reference so we can use this as a dependency in useMemo or useEffect.
    // also, annotate which tile is requested using which API
    const tileDefs = (settings.dashboard?.tiles ?? getDefaultTiles(session, settings) ?? []).map(t => {
        if (t.kpiType === KpiTypes.CarbonPerOutput)
            return {
                ...t,
                kpiType: KpiTypes.Carbon,
            };
        if (t.kpiType === KpiTypes.EnergyPerOutput)
            return {
                ...t,
                kpiType: KpiTypes.Energy,
            };
        return t;
    }).map(t => {
        return {
            ...t,
            endpoint: decideEndpoint(session, settings, t, settings.dashboard?.showPlanningData ?? false),
            kpiDefinition: getKpiDefinition(t.kpiType, getContextFromTile(t, session, settings)),
        };
    }).filter(t => t.endpoint !== undefined && t.kpiDefinition !== undefined) as TileModel[];


    // #region API requests and data retrieval ----------------------------------------------------
    const caseTileDefs = tileDefs.filter(t => t.endpoint === TimeperiodApis.Case);
    const caseDeviationTileDefs = tileDefs.filter(t => t.endpoint === TimeperiodApis.CaseDeviation);
    const eventTileDefs = tileDefs.filter(t => t.endpoint === TimeperiodApis.Event);
    // Get data from deviation- and actual endpoint
    const defaultOptions = {
        frequency: settings.dashboard?.frequency,
        sort: ["-timeperiodStartTime"],
        limit: durationLimits[settings.dashboard?.frequency ?? TimePeriodFrequencies.Week],
    };

    const isInitializing = session.project === undefined || settings.dashboard?.frequency === undefined || stats === undefined;

    const [eventTimeperiodStats, isEventTimeperiodStatsLoading] = useTimeAggregatedEventStatistics({
        ...defaultOptions,
        ...disableAllCalcOptions,
        customKpis: getCustomKpis(eventTileDefs),
        ...getCalculateOptions(eventTileDefs),
    }, {
        disable: isInitializing || !eventTileDefs.length,
        addEnergyStats: true,
    });

    const [caseTimeperiodStats, isActualTimeperiodStatsLoading] = useTimeAggregatedCaseStatistics({
        ...defaultOptions,
        ...disableAllCalcOptions,
        customKpis: getCustomKpis(caseTileDefs),
        ...getCalculateOptions(caseTileDefs),
    }, {
        // always request planning data if that's available. Otherwise,
        // deviation tiles will not display anything, as those need the "deviation" object
        // of the response
        disable: isInitializing || !caseTileDefs.length,
        addEnergyStats: true,
    });

    const [caseDeviationTimeperiodStats, isDeviationTimeperiodStatsLoading] = useDeviationTimeperiodStatistics({
        ...defaultOptions,
        ...disableAllCalcOptions,
        customKpis: getCustomKpis(caseDeviationTileDefs),
        ...getCalculateOptions(caseDeviationTileDefs),
    }, {
        disable: isInitializing || !caseDeviationTileDefs.length,
        addEnergyStats: true,
    });

    const isLoading = isStatsLoading || isActualTimeperiodStatsLoading || isDeviationTimeperiodStatsLoading || isEventTimeperiodStatsLoading;

    // #endregion

    // When we do not have planning data
    useEffect(() => {
        if (caseDeviationTimeperiodStats?.timeperiods?.length === 0 &&
            settings.dashboard?.showPlanningData &&
            !isLoading &&
            !isAllStatsLoading &&
            allStats.numFilteredTraces) {
            NotificationService.add({
                id: "no-comparison-data",
                className: "light default-accent",
                icon: "radix-exclamation-triangle",
                autoCloseDelay: Global.defaultNotificationDelay,
                summary: "dashboard.noComparisonDataTitle",
                message: "dashboard.noComparisonDataMessage",
            });

            queueMicrotask(() => {
                settings.mergeSet({
                    dashboard: {
                        showPlanningData: false,
                    },
                });
            });
        }
    }, [
        caseDeviationTimeperiodStats?.timeperiods?.length,
        settings.dashboard?.showPlanningData,
        isLoading,
        isAllStatsLoading,
        allStats?.numFilteredTraces,
    ]);

    // Perform grid layout
    const containerRef = useRef<HTMLDivElement>(null);
    const tileSize = useGridLayout(containerRef, 6, {
        minWidth: 300,
        minHeight: 250,
        desiredHeight: 300,
        xGap: 32
    });
    const tileLayout = toGridLayout(tileSize);

    // #region Tile generation --------------------------------------------------------------------
    const endDate = (() => {
        if (!stats.maxDate || !settings.dashboard?.frequency)
            return;

        const maxDate = toUserTimezoneMillis(stats.maxDate.getTime(), session.timezone);
        const prev = floorTime(stats.maxDate.getTime(), settings.dashboard.frequency, session.timezone);
        const next = addStep(prev, session.timezone, settings.dashboard.frequency, false, 1);

        // if maximum date is equal to next, use that as our end date. Otherwise, use prev.
        return (timestampSort(maxDate, next) === 0) ? next : prev;
    })();

    const { dashboardTiles, lastXValue } = useMemo(() => {
        // Check if some requests are still ongoing, and if so, display tiles with a spinner
        // until we're ready.
        if (!endDate || (!!caseTileDefs.length && !caseTimeperiodStats) || (!!eventTileDefs.length && !eventTimeperiodStats) || (!!caseDeviationTileDefs.length && !caseDeviationTimeperiodStats))
            return {
                dashboardTiles: (caseTileDefs.concat(caseDeviationTileDefs).concat(eventTileDefs)).map((tileDef, idx) => idx < 6 ? getLoadingTile(tileDef, idx) : undefined).filter(d => !!d),
                lastXValue: undefined,
            };

        // The dashboard should show only "complete" datapoints. Meaning, that if the maximum timestamp
        // from all cases is within e.g. a week, this week is not considered complete and removed
        // from the charts. We make an exception from this rule only for daily data because the time 
        // period is very short anyway.
        const filterFunc = settings.dashboard?.frequency === TimePeriodFrequencies.Day ? () => true :
            (t: TimeperiodDeviationStatisticsSchema | TimeperiodCaseAggregationStatisticsSchema) =>
                timestampSort(toUserTimezone(t.timeperiodStartTime, session.timezone), endDate) < 0;

        // data containing only "complete" timeperiods. or undefined!
        const filteredCaseDeviationData: PerTimeperiodDeviationStatisticsSchema | undefined = !caseDeviationTimeperiodStats ? undefined : { ...caseDeviationTimeperiodStats, timeperiods: caseDeviationTimeperiodStats?.timeperiods?.filter(filterFunc) };
        const filteredCaseData: PerTimeperiodStatisticsSchema | undefined = !caseTimeperiodStats ? undefined : { ...caseTimeperiodStats, timeperiods: caseTimeperiodStats?.timeperiods?.filter(filterFunc) };
        const filteredActualEventsData: PerTimeperiodStatisticsSchema | undefined = !eventTimeperiodStats ? undefined : { ...eventTimeperiodStats, timeperiods: eventTimeperiodStats?.timeperiods?.filter(filterFunc) };

        // Determine most recent timestamp
        const lastXActual = toUserTimezone(filteredCaseData?.timeperiods[0]?.timeperiodStartTime ?? "2000-01-01T00:00:00Z", session.timezone);
        const lastXDeviation = toUserTimezone(filteredCaseDeviationData?.timeperiods?.[0]?.timeperiodStartTime ?? "2000-01-01T00:00:00Z", session.timezone);
        const lastXEvents = toUserTimezone(filteredActualEventsData?.timeperiods?.[0]?.timeperiodStartTime ?? "2000-01-01T00:00:00Z", session.timezone);
        const lastXValue = [lastXActual, lastXDeviation, lastXEvents].sort((a, b) => {
            return timestampSort(a, b);
        })[2];

        // Generate dashboard tiles
        return {
            dashboardTiles: (tileDefs ?? []).map((tileDef, idx) => generateDashboadTile(idx, tileDef, {
                case: filteredCaseData,
                deviation: filteredCaseDeviationData,
                events: filteredActualEventsData,
            }, {
                isLoading: settings.dashboard?.frequency === undefined,
                isCaseLoading: isActualTimeperiodStatsLoading,
                isCaseDeviationLoading: isDeviationTimeperiodStatsLoading,
                isEventLoading: isEventTimeperiodStatsLoading,
            },
            settings.dashboard?.frequency ?? TimePeriodFrequencies.Week)).filter(t => !!t) as JSX.Element[],
            lastXValue,
        };
    }, [
        session.timezone,
        session.locale,
        stats.maxDate,
        isLoading,
        isStatsLoading,
        isActualTimeperiodStatsLoading,
        isDeviationTimeperiodStatsLoading,
        isEventTimeperiodStatsLoading,
        settings.dashboard?.frequency,
        settings.dashboard?.showPlanningData,
        caseTimeperiodStats,
        caseDeviationTimeperiodStats,
        eventTimeperiodStats,
        selectedTileIdx,
        JSON.stringify(tileDefs),
    ]);

    useEffect(() => {
        const isViewReady = dashboardTiles !== undefined && dashboardTiles.every(d => !d?.props.isLoading) &&
            allStats?.numFilteredTraces !== undefined && !isAllStatsLoading;

        if (!isViewReady)
            return;

        const hasTilesWithData = dashboardTiles?.some(d => d !== undefined && !!d.props.values?.length);
        const hasAtLeastOneOrder = (allStats.numFilteredTraces ?? 0) > 0;

        if (hasTilesWithData ||
            settings.dashboard?.frequency === TimePeriodFrequencies.Day ||
            allStats === undefined ||
            !hasAtLeastOneOrder)
            return;

        const decreasedFrequency = settings.dashboard?.frequency === TimePeriodFrequencies.Year ? TimePeriodFrequencies.Month :
            settings.dashboard?.frequency === TimePeriodFrequencies.Month ? TimePeriodFrequencies.Week : TimePeriodFrequencies.Day;

        queueMicrotask(() => {
            settings.mergeSet({
                dashboard: {
                    frequency: decreasedFrequency,
                },
            });
        });

        if ((settings.previewFilters ?? settings.filters).length === 0)
            setMaxFrequency(decreasedFrequency);
    }, [
        allStats?.numFilteredTraces,
        dashboardTiles,
    ]);
    // #endregion

    const isNoDataAvailable = !isAllStatsLoading && allStats.numFilteredTraces === 0;
    const isDateTitleLoading = !isNoDataAvailable && (isLoading || isStatsLoading || !lastXValue);

    const [showProjectLoadingSpinner, setShowProjectLoadingSpinner] = useState(!Global.projectLoadingSpinnerHasBeenShown);

    useEffect(() => {
        if (isLoading || !lastXValue)
            return;

        setShowProjectLoadingSpinner(false);
    }, [
        isLoading
    ]);

    const addFavoritesModalRef = useRef<IModal>(null);

    const setFrequency = (f: TimePeriodFrequencies) => settings.mergeSet({
        dashboard: {
            frequency: f,
        },
    });

    const showBigSpinner = session.project !== undefined && showProjectLoadingSpinner && (isLoading || lastXValue === undefined || !dashboardTiles?.length);

    if (session.project === undefined)
        return <div className="dashboard dashboardFilter"></div>;

    return <div className="dashboard dashboardFilter">
        {showBigSpinner && <Spinner isLoading={isDateTitleLoading} showProjectLoadingSpinner={showProjectLoadingSpinner} />}
        <div className={classNames(["dashboardContainer", settings.filterEditor.showFilterEditor && "filterExpanded", isLoading && showProjectLoadingSpinner && "hidden"])} ref={containerRef}>
            {!showBigSpinner && <div className="dashboardContent">
                <div className="pageHeader">
                    <div className="greeting">{i18n.t("common.dashboard")}
                    </div>

                    {!isNoDataAvailable && <div className="buttons">
                        {/* Compare with plan */}
                        {hasPlanningData && <div>
                            <div className="buttonesque"
                                onClick={() => settings.mergeSet({
                                    dashboard: {
                                        showPlanningData: !settings.dashboard?.showPlanningData,
                                    },
                                })}>
                                <ReactSwitch
                                    id="switch-planning-comparison"
                                    height={16}
                                    handleDiameter={12}
                                    width={27}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    offColor={colors["$gray-2"]}
                                    onColor={colors["$primary-500"]}
                                    checked={!!settings.dashboard?.showPlanningData}
                                    onChange={noop}
                                />
                                <label className="clickable">
                                    {i18n.t("common.planComparison")}
                                </label>
                            </div>
                        </div>}

                        {/* Time scale */}
                        <Menu className="menuLight" items={[{
                            title: i18n.t("units.day").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Day); }
                        }, {
                            title: i18n.t("units.week").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Week); },
                            disabled: maxFrequency === TimePeriodFrequencies.Day,
                        }, {
                            title: i18n.t("units.month").toString(),
                            onClick: () => { setFrequency(TimePeriodFrequencies.Month); },
                            disabled: maxFrequency === TimePeriodFrequencies.Day ||
                                maxFrequency === TimePeriodFrequencies.Week,
                        }, {
                            title: i18n.t("units.year").toString(),
                            disabled: maxFrequency !== TimePeriodFrequencies.Year,
                            onClick: () => { setFrequency(TimePeriodFrequencies.Year); }
                        }]}>
                            <div className="buttonesque" data-testid="frequency-selection">
                                <svg className="svg-icon xsmall brandHover"><use xlinkHref="#radix-calendar" /></svg>
                                <ValueSpinner isLoading={settings.dashboard?.frequency === undefined}>
                                    <>
                                        {settings.dashboard?.frequency !== undefined && i18n.t("units." + settings.dashboard.frequency.toString())}
                                    </>
                                </ValueSpinner>
                            </div>
                        </Menu>

                        <div className="buttonesque" title={i18n.t("favorites.saveView").toString()} onClick={() => {
                            addFavoritesModalRef.current?.show();
                        }}>
                            <svg className="svg-icon xsmall clickable brandHover">
                                <use xlinkHref="#radix-star" />
                            </svg>
                        </div>
                        {session.project?.isSharedWithOrganization && <div className="buttonesque" title={i18n.t("common.shareView").toString()}
                            onClick={async () => {
                                const sharingWorked = await shareAsync(session, settings);

                                trackEvent({
                                    category: "Interactions",
                                    action: "Shared view",
                                    name: sharingWorked ? "navigator" : "fallback",
                                });

                                setShowShareModal(!sharingWorked);
                            }}>
                            <svg className="svg-icon xsmall clickable brandHover">
                                <use xlinkHref="#radix-share-1" />
                            </svg>
                        </div>}
                    </div>}
                </div>
                <div className="grid" ref={containerRef}>
                    {(dashboardTiles?.length ?? 0) > 0 && !isNoDataAvailable && <div className="tilesContainer" style={{ ...tileLayout }}>
                        {dashboardTiles}
                    </div>}
                    <NoDataAvailable
                        message="dashboard.noDataAvailable"
                        visible={isNoDataAvailable}
                    />
                </div>
            </div>}

            {!showBigSpinner && <FilterEditor />}
        </div>

        <EditFavoritesModal ref={addFavoritesModalRef} />

        {showShareModal && <ShareModal onDone={async () => setShowShareModal(false)} />}

        {tileEditIdx !== undefined && <DashboardTileSettingsModal
            initialValue={tileDefs?.[tileEditIdx ?? 0]}
            onCancel={() => {
                setTileEditIdx(undefined);
            }}
            onChange={(kpi, statistic, quantity) => {
                const newTileDefs = (tileDefs ?? []).map((t, idx) => {
                    if (idx !== tileEditIdx)
                        return t;

                    return {
                        kpiType: kpi,
                        statistic: statistic,
                        quantity: quantity,
                    };
                });
                setTileEditIdx(undefined);
                settings.mergeSet({
                    dashboard: {
                        tiles: newTileDefs,
                    },
                });
            }}
        />}
    </div>;

    function getLoadingTile(tile: DashboadTileSettings, idx: number) {
        const key = `tile-${tile.kpiType ?? ""}-${tile.quantity ?? ""}-${tile.statistic}-${idx}`;
        return <DashboardTile
            key={key}
            values={[]}
            planValues={[]}
            value={0}
            kpiType={tile.kpiType}
            planValue={0}
            prevValue={0}
            isLoading={true}
            unit={Formatter.defaultUnit}
            scales={Formatter.defaultUnit.getUnits({})}
            isLessBetter={true}
            title={""}
            xTickFrequency={settings.dashboard?.frequency ?? TimePeriodFrequencies.Week}
        />;
    }

    function generateDashboadTile(idx: number, tile: TileModel | undefined, statsData: {
        case: PerTimeperiodStatisticsSchema | undefined,
        events: PerTimeperiodStatisticsSchema | undefined,
        deviation: PerTimeperiodDeviationStatisticsSchema | undefined,
    }, loadingState: LoadingState, frequency: TimePeriodFrequencies) {
        if (!tile)
            return null;

        const isTileLoading = (tile.endpoint === TimeperiodApis.Case && loadingState.isCaseLoading) ||
            (tile.endpoint === TimeperiodApis.CaseDeviation && loadingState.isCaseDeviationLoading) ||
            (tile.endpoint === TimeperiodApis.Event && loadingState.isEventLoading);

        const key = `tile-${tile?.kpiType ?? ""}-${tile?.quantity ?? ""}-${tile?.statistic}-${idx}`;

        const unit = getUnit(tile.kpiDefinition.unit, tile.statistic) ?? tile.kpiDefinition.unit as UnitMetadata;

        const scale = unit.name === "percent" ? 100 : 1;

        const localPath = getTimeperiodStatisticPath(tile.kpiDefinition, tile.statistic) ??
            getProductStatisticPath(tile.kpiDefinition, tile.statistic);

        const data = tile.endpoint === TimeperiodApis.Case ?
            statsData.case :
            tile.endpoint === TimeperiodApis.Event ?
                statsData.events :
                statsData.deviation;

        const times = data?.timeperiods.map(t => new Date(t.timeperiodStartTime).getTime()) ?? [];
        const isCount = localPath === "caseCount" || localPath === "count";
        const actualPath = isCount ?
            (tile.endpoint === TimeperiodApis.Case ? "count" : "caseCount") :
            localPath?.startsWith("deviation") ? localPath :
                (tile.endpoint === TimeperiodApis.Case || tile.endpoint === TimeperiodApis.Event ? localPath : "actual." + localPath);

        const values = isTileLoading ? [] : data?.timeperiods.map((t, idx) => {
            const value = get(t, actualPath ?? "");

            if (value === undefined)
                return;

            return {
                y: value * scale,
                x: times[idx]
            };
        }).filter(a => a !== undefined) as Point[] ?? [];
        const planValues = (isTileLoading || !settings.dashboard?.showPlanningData) ? [] : data?.timeperiods.map((t, idx) => {

            const value = get(t, "planned." + localPath);
            if (value === undefined)
                return;
            return {
                y: value * scale,
                x: times[idx],
            };

        }).filter(a => a !== undefined) as Point[] ?? [];

        const value = values[0]?.y;
        const prevValue = values[1]?.y;
        const planValue = planValues[0]?.y;

        if (tile === undefined || (value === undefined && !isTileLoading))
            return <div className="dashboardTile" key={`empty-tile-${idx}`}>
                <div className="header">
                    <div className="title">
                        <svg className="svg-icon xxsmall clickable brandHover edit" data-testid="tile-options" onClick={(e) => {
                            setTileEditIdx(idx);
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <use xlinkHref="#radix-pencil-1" />
                        </svg>
                    </div>
                </div>
            </div>;

        return <DashboardTile
            key={key}
            spotlight={buildDashboardSpotlightId(tile.kpiDefinition.spotlightId, tile.statistic)}
            statistic={tile.statistic}
            values={values}
            planValues={planValues}
            kpiType={tile.kpiType}
            value={value}
            planValue={planValue}
            prevValue={prevValue}
            isLoading={isTileLoading}
            unit={unit}
            scales={getLongUnit(unit).getUnits({
                baseQuantity: tile.quantity,
            })}
            isLessBetter={tile.kpiDefinition.isLessBetter}
            title={i18n.t(tile.kpiDefinition.label).toString()}
            xTickFrequency={frequency}
            onSettingsClick={() => {
                setTileEditIdx(idx);
            }}
            isSelected={Global.isTouchEnabled && idx === selectedTileIdx}
            onClick={() => {
                setSelectedTileIdx(selectedTileIdx === idx? undefined : idx);
            }}
        />;
    }
}

/**
 * Serializes the dashboard settings into local storage
 */
function writeDashboardSettings(session: SessionType, settings: SettingsContextType, projectId?: string) {
    const tileSettingsKey = `${session.user?.hash ?? "?"}/settings/${projectId ?? session.projectId}/dashboard-ng`;
    const json = JSON.stringify({
        ...settings.dashboard,
        // prevent internal state to leak into local storage
        tiles: !settings.dashboard?.tiles ? undefined :
            settings.dashboard.tiles.map(t => {
                return {
                    kpiType: t.kpiType,
                    statistic: t.statistic,
                    quantity: t.quantity,
                };
            }),
    });
    localStorage.setItem(tileSettingsKey, json);
}

/**
 * Loads dashboard settings from local storage
 * @returns DashboardSettingsType instance or undefined
 */
export function getDashboardSettings(session: SessionType, projectId: string | undefined) {
    const tileSettingsKey = `${session.user?.hash ?? "?"}/settings/${projectId ?? session.projectId}/dashboard-ng`;
    const json = localStorage.getItem(tileSettingsKey);
    if (json)
        try {
            return JSON.parse(json) as DashboardSettingsType;
        } catch (e) {
            // Ignore
        }
    return undefined;
}

export function getDefaultTiles(session: SessionType, settings: SettingsType) {
    const result = [
        {type: KpiTypes.ThroughputTime, statistic: StatisticTypes.Mean},
        {type: KpiTypes.ProductionProcessRatio, statistic: StatisticTypes.Mean},
        {type: KpiTypes.QueuingTime, statistic: StatisticTypes.Mean},
        {type: KpiTypes.ThroughputRate, statistic: StatisticTypes.Mean},
        {type: KpiTypes.ScrapRatio, statistic: StatisticTypes.Mean},
        {type: KpiTypes.Carbon, statistic: StatisticTypes.Mean},
        {type: KpiTypes.GoodQuantity, statistic: StatisticTypes.Sum},
        {type: KpiTypes.DeviationThroughputTime, statistic: StatisticTypes.Mean},
        {type: KpiTypes.OnTimeDelivery, statistic: StatisticTypes.Mean},
        {type: KpiTypes.OrderCount, statistic: StatisticTypes.Sum},
    ].map(kpi => {
        const kpiDef = getKpiDefinition(kpi.type, getContextOverride(session, settings, { statistic: kpi.statistic }));
        if (!kpiDef)
            return undefined;

        const isQuantityMissing = kpiDef.isQuantityDependent && kpiDef.allowedQuantities.actual.case[0] === undefined;
        const isPlanMissing = kpiDef.requiresPlanningData && session.project?.eventKeysPlan === undefined;
        const isRequiredEventKeysMissing = kpiDef.requiredEventKeys && kpiDef.requiredEventKeys.some(k => get(session.project, k) === undefined);

        if (!kpiDef || isQuantityMissing || isPlanMissing || isRequiredEventKeysMissing)
            return undefined;

        return {
            kpiType: kpi.type,
            statistic: kpi.statistic,
            quantity: kpiDef.isQuantityDependent ? kpiDef.allowedQuantities.actual.case[0] : undefined,
        } as DashboadTileSettings;
    }).filter(d => d !== undefined).slice(0, 6) as DashboadTileSettings[];

    return result;
}

function buildDashboardSpotlightId(kpiSpotlightId: string, statistic: StatisticTypes) {
    return kpiSpotlightId + "-Dashboard-" + capitalize(statistic);
}


/**
 * Decides for a given tile, if the deviation or case endpoint should be used
 * @param isDeviationEnabled Value of the planning data switch
 * @returns Endpoint type or undefined if the tile cannot be displayed
 */
function decideEndpoint(session: SessionType, settings: SettingsType, tile: DashboadTileSettings, isDeviationEnabled: boolean, context: "case" | "node" = "case") {
    const hasPlanningData = session.project?.eventKeysPlan !== undefined;
    const kpiDef = getKpiDefinition(tile.kpiType, getContextFromTile(tile, session, settings));
    if (!kpiDef)
        return undefined;

    if ([KpiTypes.BusyTime, KpiTypes.ProductionTime, KpiTypes.SetupTime, KpiTypes.FailureTime, KpiTypes.ScrapQuantity, KpiTypes.InterruptionTime,
        KpiTypes.TechnicalLosses, KpiTypes.OrganizationalLosses, KpiTypes.ProcessLosses, KpiTypes.QualityLosses].includes(tile.kpiType))
        return TimeperiodApis.Event;

    if (kpiDef.useDeviationApi === true && !hasPlanningData)
        // This tile cannot be displayed. It requires planning data, but don't have that.
        return undefined;

    if (kpiDef.useDeviationApi === true)
        return TimeperiodApis.CaseDeviation;

    if (!hasPlanningData || !isDeviationEnabled)
        // If we don't have planning data, it's also a simple case
        return TimeperiodApis.Case;

    if (kpiDef.useDeviationApi === false)
        return TimeperiodApis.Case;

    // If we have a quantity dependent tile, but the planning quantities don't support
    // the selected one, we have to fall back to actual only
    if (settings.dashboard?.showPlanningData &&
        kpiDef.isQuantityDependent &&
        tile.quantity &&
        !kpiDef.allowedQuantities.plan.case.includes(tile.quantity) &&
        kpiDef.allowedQuantities.actual.case.includes(tile.quantity))
        return TimeperiodApis.Case;

    if (tile.quantity !== undefined) {
        // Check if the quantity is available for deviation API requests. That requires
        // the planning data to contain certain columns, and these may not have been assigned.
        if (!kpiDef.allowedQuantities.plan[context].includes(tile.quantity))
            // Desired quantity is not OK for deviation API requests, fall back to actual only
            return TimeperiodApis.Case;
    }

    // If we reach this point, we can use the deviation API
    return TimeperiodApis.CaseDeviation;
}

/**
 * Gets the custom KPIs that are used in the tiles provided
 */
function getCustomKpis(tileDefs: TileModel[] | undefined) {
    return uniqBy(flatMap((tileDefs ?? []).map(def => {
        return def.kpiDefinition.eventOverTimeCustomKpis ?? def.kpiDefinition.productCustomKpis ?? [];
    })).filter(e => e !== undefined), e => e!.id) as CustomKpi[];
}

/**
 * Returns a StatsCalculationRequest instance where all properties are true that are used
 * in the tiles provided
 */
function getCalculateOptions(tileDefs: TileModel[] | undefined) {
    const result: { [key: string]: boolean } = {};

    for (const tile of tileDefs ?? []) {
        Object.keys(tile.kpiDefinition.apiParameters ?? {}).forEach(key => {
            const prop = key as keyof StatsCalculationRequest;
            if (isBoolean(tile.kpiDefinition.apiParameters![prop]) &&
                tile.kpiDefinition.apiParameters![prop] === true)
                result[key] = true;
        });
    }

    return result as StatsCalculationRequest;
}